import React, { useState, useEffect, useRef, useCallback } from 'react';
import { MoreVertical, AlertCircle, User, Building2, Bell, ChevronRight, X, Trash2, Check, Search } from 'lucide-react';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "./ui/alert-dialog";
import { 
  collection, 
  query, 
  where, 
  getDocs, 
  updateDoc,
  deleteDoc,
  doc,
  onSnapshot,
  addDoc,
  writeBatch,
  orderBy,
  startAfter,
  limit
} from 'firebase/firestore';
import { db } from '../config/firebase';
import { auth } from '../config/firebase';
import { toast } from 'react-hot-toast';
import { API_URL } from '../services/api';
import { COUNTRY_MAPPING } from '../constants/countries';
import { getResultTitle, getRiskTag } from '../utils/formatters';
import NotificationBubble from "./ui/NotificationBubble";
import SearchFilter from "./SearchFilter";
import { debounce } from 'lodash';

const MonitoringDashboard = ({ user, onMonitorItemClick }) => {
  const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
  const [monitoredItems, setMonitoredItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState('all');
  const [showNotifications, setShowNotifications] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  const [lastDoc, setLastDoc] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const ITEMS_PER_PAGE = 10;

  // Add new state variables for search
  const [isSearchMode, setIsSearchMode] = useState(false);
  const [allItems, setAllItems] = useState([]);
  const [displayedItems, setDisplayedItems] = useState([]);

  // Add new state for search loading
  const [isSearchLoading, setIsSearchLoading] = useState(false);

  const fetchData = async () => {
    try {
      setLoading(true);
      
      let monitoringQuery;
      
      if (filter === 'all') {
        monitoringQuery = query(
          collection(db, 'users', user.uid, 'monitoring'),
          orderBy('timestamp', 'desc'),
          limit(ITEMS_PER_PAGE)
        );
      } else {
        monitoringQuery = query(
          collection(db, 'users', user.uid, 'monitoring'),
          where('searchType', '==', filter),
          orderBy('timestamp', 'desc'),
          limit(ITEMS_PER_PAGE)
        );
      }

      const monitoringSnapshot = await getDocs(monitoringQuery);
      const items = monitoringSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      setDisplayedItems(items);
      setMonitoredItems(items);
      setLastDoc(monitoringSnapshot.docs[monitoringSnapshot.docs.length - 1]);
      setHasMore(monitoringSnapshot.docs.length === ITEMS_PER_PAGE);

      // Updated notifications fetch with detailed logging
      // console.log('Starting notifications fetch for user:', user.uid);
      
      const notificationsQuery = query(
        collection(db, 'users', user.uid, 'notifications'),
        orderBy('timestamp', 'desc')
      );
      
      const notificationsSnapshot = await getDocs(notificationsQuery);
      console.log('Raw notifications snapshot:', {
        size: notificationsSnapshot.size,
        empty: notificationsSnapshot.empty
      });
      
      const newNotifications = notificationsSnapshot.docs.map(doc => {
        const data = doc.data();
        console.log('Raw notification data:', data);
        
        return {
          id: doc.id,
          ...data,
          entityName: data.entityName,
          entityId: data.entityId,
          read: data.read || false,
          changes: {
            hasChanges: data.changes?.hasChanges || false,
            importantUpdates: data.changes?.importantUpdates || [],
            relatedRiskFactors: data.changes?.relatedRiskFactors || [],
            significantChanges: data.changes?.significantChanges || [],
            mediaUpdates: (data.changes?.mediaUpdates || []).map(update => ({
              id: update.id,
              name: update.name,
              mediaChanges: {
                added: update.mediaChanges?.added || [],
                significantChanges: update.mediaChanges?.significantChanges || []
              }
            })),
            added: data.changes?.added || [],
            removed: data.changes?.removed || [],
            message: data.changes?.message || 'Changes detected'
          },
          timestamp: data.timestamp?.toDate?.() || 
                    (data.timestamp?.seconds ? new Date(data.timestamp.seconds * 1000) : new Date())
        };
      });

      console.log('Processed notifications:', newNotifications);
      setNotifications(newNotifications);

    } catch (error) {
      console.error('Error fetching data:', error);
      toast.error('Failed to load monitoring data');
    } finally {
      setLoading(false);
    }
  };

  // Update fetchAllForSearch to handle the current search query
  const fetchAllForSearch = async (currentQuery) => {
    try {
      setLoading(true);
      
      const allItemsQuery = query(
        collection(db, 'users', user.uid, 'monitoring'),
        orderBy('timestamp', 'desc')
      );
      
      const querySnapshot = await getDocs(allItemsQuery);
      const items = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      setAllItems(items);
      // Use the passed query parameter instead of searchQuery state
      const filteredItems = filterItems(items, filter, currentQuery);
      setDisplayedItems(filteredItems.slice(0, ITEMS_PER_PAGE));
      setMonitoredItems(filteredItems.slice(0, ITEMS_PER_PAGE));
      setHasMore(filteredItems.length > ITEMS_PER_PAGE);
      
    } catch (error) {
      console.error('Error fetching search data:', error);
      toast.error('Failed to load search data');
    } finally {
      setLoading(false);
    }
  };

  // Update loadMore function
  const loadMore = async () => {
    if (!hasMore || isLoadingMore) return;
    setIsLoadingMore(true);
    
    try {
      if (isSearchMode) {
        const currentLength = displayedItems.length;
        const filteredItems = filterItems(allItems);
        const nextItems = filteredItems.slice(currentLength, currentLength + ITEMS_PER_PAGE);
        
        setDisplayedItems(prev => [...prev, ...nextItems]);
        setMonitoredItems(prev => [...prev, ...nextItems]);
        setHasMore(currentLength + ITEMS_PER_PAGE < filteredItems.length);
      } else {
        let moreMonitoringQuery;
        
        if (filter === 'all') {
          moreMonitoringQuery = query(
            collection(db, 'users', user.uid, 'monitoring'),
            orderBy('timestamp', 'desc'),
            startAfter(lastDoc),
            limit(ITEMS_PER_PAGE)
          );
        } else {
          moreMonitoringQuery = query(
            collection(db, 'users', user.uid, 'monitoring'),
            where('searchType', '==', filter),
            orderBy('timestamp', 'desc'),
            startAfter(lastDoc),
            limit(ITEMS_PER_PAGE)
          );
        }
        
        const querySnapshot = await getDocs(moreMonitoringQuery);
        const moreItems = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        
        setDisplayedItems(prev => [...prev, ...moreItems]);
        setMonitoredItems(prev => [...prev, ...moreItems]);
        setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
        setHasMore(querySnapshot.docs.length === ITEMS_PER_PAGE);
      }
    } catch (error) {
      console.error('Error loading more items:', error);
      toast.error('Failed to load more items');
    } finally {
      setIsLoadingMore(false);
    }
  };

  // Update debouncedSearch to pass the current query
  const debouncedSearch = useCallback(
    debounce(async (query) => {
      if (query) {
        setIsSearchLoading(true);
        try {
          if (!isSearchMode) {
            setIsSearchMode(true);
            await fetchAllForSearch(query);
          }
        } finally {
          setIsSearchLoading(false);
        }
      } else {
        setIsSearchMode(false);
        await fetchData();
      }
    }, 300),
    []  // Empty dependency array since we're passing query directly
  );

  // Update handleSearchChange to handle single-letter searches
  const handleSearchChange = (query) => {
    setSearchQuery(query);
    
    // Always filter if we have data, regardless of query length
    if (isSearchMode && allItems.length > 0) {
      const filteredItems = filterItems(allItems, filter, query);
      setDisplayedItems(filteredItems.slice(0, ITEMS_PER_PAGE));
      setMonitoredItems(filteredItems.slice(0, ITEMS_PER_PAGE));
      setHasMore(filteredItems.length > ITEMS_PER_PAGE);
    } else if (query.length > 0) { // Trigger search for any non-empty query
      debouncedSearch(query);
    } else {
      // Reset to normal view if query is empty
      setIsSearchMode(false);
      fetchData();
    }
  };

  // Update filter handler
  const handleFilterChange = async (newFilter) => {
    setFilter(newFilter);
    setLastDoc(null); // Reset pagination
    setHasMore(true);
    
    if (isSearchMode) {
      const filteredItems = filterItems(allItems, newFilter, searchQuery);
      setDisplayedItems(filteredItems.slice(0, ITEMS_PER_PAGE));
      setMonitoredItems(filteredItems.slice(0, ITEMS_PER_PAGE));
      setHasMore(filteredItems.length > ITEMS_PER_PAGE);
    } else {
      await fetchData();
    }
  };

  // Add filterItems function
  const filterItems = (items, currentFilter = filter, query = searchQuery) => {
    return items.filter(item => {
      const matchesFilter = currentFilter === 'all' || item.searchType === currentFilter;
      
      if (!query) return matchesFilter;
      
      const searchLower = query.toLowerCase();
      const matchesSearch = (item.searchType === 'individual' 
        ? item.individualName?.toLowerCase().includes(searchLower)
        : item.companyName?.toLowerCase().includes(searchLower)) ||
        item.country?.toLowerCase().includes(searchLower) ||
        item.organization?.toLowerCase().includes(searchLower) ||
        item.designation?.toLowerCase().includes(searchLower);
      
      return matchesFilter && matchesSearch;
    });
  };

  // Update initial useEffect
  useEffect(() => {
    if (!isSearchMode && user?.uid) {
      fetchData();
    }
  }, [user?.uid, filter]);

  useEffect(() => {
    fetchData();
  }, [user.uid]);

  const handleMonitoringUpdate = async (itemId, frequency) => {
    try {
      const docRef = doc(db, 'users', user.uid, 'monitoring', itemId);
      
      // Update the document in Firestore
      await updateDoc(docRef, {
        frequency,
        lastUpdated: new Date()
      });
      
      // Helper function to update items in a state array
      const updateItemInArray = (items) => items.map(item => 
        item.id === itemId ? {
          ...item,
          frequency,
          lastUpdated: new Date() // Also update lastUpdated in local state
        } : item
      );
      
      // Update all relevant state variables
      setDisplayedItems(updateItemInArray);
      setMonitoredItems(updateItemInArray);
      
      // Update allItems if in search mode
      if (isSearchMode) {
        setAllItems(updateItemInArray);
      }
      
      toast.success('Monitoring frequency updated');
    } catch (error) {
      console.error('Error updating monitoring frequency:', error);
      toast.error('Failed to update monitoring frequency');
      
      // Refresh data in case of error to ensure consistency
      if (isSearchMode) {
        fetchAllForSearch(searchQuery);
      } else {
        fetchData();
      }
    }
  };

  const handleDelete = async (itemId) => {
    try {
      // Show loading toast that can be updated
      const toastId = toast.loading('Removing entity...');

      // Delete the monitored item
      const docRef = doc(db, 'users', user.uid, 'monitoring', itemId);
      
      // Get all notifications for this entity
      const notificationsQuery = query(
        collection(db, 'users', user.uid, 'notifications'),
        where('entityId', '==', itemId)
      );
      const notificationsSnapshot = await getDocs(notificationsQuery);
      
      // Create a batch using writeBatch
      const batch = writeBatch(db);
      
      // Add monitored item deletion to batch
      batch.delete(docRef);
      
      // Add all notification deletions to batch
      notificationsSnapshot.docs.forEach(notificationDoc => {
        batch.delete(notificationDoc.ref);
      });

      // Update local states BEFORE the batch commit
      // Update both displayedItems and monitoredItems
      setDisplayedItems(prev => prev.filter(item => item.id !== itemId));
      setMonitoredItems(prev => prev.filter(item => item.id !== itemId));
      // Update notifications state
      setNotifications(prevNotifications => 
        prevNotifications.filter(notification => notification.entityId !== itemId)
      );
      
      // Execute all deletions in a single batch
      await batch.commit();
      
      // Update toast to success
      toast.success('Entity removed from monitoring', { id: toastId });

    } catch (error) {
      console.error('Error deleting monitored item:', error);
      toast.error('Failed to remove entity from monitoring');
      // Refresh the data in case of error to ensure consistency
      fetchData();
    }
  };

  const formatFrequency = (frequency) => {
    return frequency.charAt(0).toUpperCase() + frequency.slice(1);
  };

  // Menu component updated with capitalized options
  const MonitoringMenu = ({ item, onRefresh }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isTestingMonitor, setIsTestingMonitor] = useState(false);
    const menuRef = useRef(null);

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
          setIsOpen(false);
        }
      };

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);

    const testMonitoring = async () => {
      try {
        setIsTestingMonitor(true);
        const toastId = toast.loading('Running monitoring check...');
        
        const idToken = await auth.currentUser.getIdToken();
        
        const response = await fetch(`${API_URL}/test-monitoring`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${idToken}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ entityId: item.id })
        });
        
        if (!response.ok) {
          const errorText = await response.text();
          console.error('Response not OK:', response.status, errorText);
          throw new Error(`Failed to test monitoring: ${response.status}`);
        }
        
        const data = await response.json();

        toast.dismiss(toastId);
        toast.success(data.result?.message || 'Monitoring check completed');
        
        if (onRefresh) onRefresh();
      } catch (error) {
        console.error('Error testing monitoring:', error);
        toast.error(`Failed to test monitoring: ${error.message}`);
      } finally {
        setIsTestingMonitor(false);
        setIsOpen(false);
      }
    };

    return (
      <div className="relative" onClick={e => e.stopPropagation()} ref={menuRef}>
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="p-1 rounded-full hover:bg-gray-100"
        >
          <MoreVertical className="w-5 h-5 text-gray-500" />
        </button>
        
        {isOpen && (
          <div className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10">
            <div className="py-1">
              <button
                onClick={() => {
                  handleMonitoringUpdate(item.id, 'Daily');
                  setIsOpen(false);
                }}
                className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              >
                Monitor Daily
              </button>
              <button
                onClick={() => {
                  handleMonitoringUpdate(item.id, 'Weekly');
                  setIsOpen(false);
                }}
                className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              >
                Monitor Weekly
              </button>
              {isLocalhost && (
                <button
                  onClick={testMonitoring}
                  disabled={isTestingMonitor}
                  className={`block w-full text-left px-4 py-2 text-sm ${
                    isTestingMonitor 
                      ? 'text-gray-400 cursor-not-allowed' 
                      : 'text-blue-600 hover:bg-gray-100'
                  }`}
                >
                  {isTestingMonitor ? 'Testing...' : 'Test Monitor Now'}
                </button>
              )}
              <button
                onClick={() => {
                  handleDelete(item.id);
                  setIsOpen(false);
                }}
                className="block w-full text-left px-4 py-2 text-sm text-red-600 hover:bg-gray-100"
              >
                Delete
              </button>
            </div>
          </div>
        )}
      </div>
    );
  };

  // Add this helper function at the top level of the component
  const getDisplayCountry = (countryCode) => {
    if (!countryCode || countryCode === 'zz') {
      return '🌍 Global';
    }
    return COUNTRY_MAPPING[countryCode.toLowerCase()] || countryCode;
  };

  // Add function to mark notification as read
  const markNotificationAsRead = async (notificationId) => {
    try {
      const notificationRef = doc(db, 'users', user.uid, 'notifications', notificationId);
      await updateDoc(notificationRef, {
        read: true
      });
    } catch (error) {
      console.error('Error marking notification as read:', error);
    }
  };

  const markAllNotificationsAsReadForItem = async (entityId) => {
    try {
      // Get all unread notifications for this specific entity
      const notificationsSnapshot = await getDocs(query(
        collection(db, 'users', user.uid, 'notifications'),
        where('entityId', '==', entityId),
        where('read', '==', false)
      ));
      
      // Mark notifications as read for this entity only
      const updatePromises = notificationsSnapshot.docs.map(doc => 
        updateDoc(doc.ref, { read: true })
      );
      
      // Reset alerts count to 0 since we're marking all as read
      const monitoringRef = doc(db, 'users', user.uid, 'monitoring', entityId);
      updatePromises.push(updateDoc(monitoringRef, { alerts: 0 }));
      
      await Promise.all(updatePromises);
      fetchData();
    } catch (error) {
      console.error('Error marking notifications as read:', error);
    }
  };

  const clearAlertsForItem = async (itemId) => {
    try {
      const docRef = doc(db, 'users', user.uid, 'monitoring', itemId);
      await updateDoc(docRef, {
        alerts: 0
      });
      // Refresh the list to show updated alerts count
      fetchData();
    } catch (error) {
      console.error('Error clearing alerts:', error);
    }
  };

  // Add helper function before NotificationsPanel component
  const cleanAndTransformTag = (tag) => {
    // Remove both 'ai.' prefix and handle any other prefixes
    const cleanTag = tag.replace(/^(ai\.)?/, '');
    return getRiskTag(cleanTag);
  };

  const NotificationsPanel = () => {
    if (!showNotifications) return null;

    // Add detailed debug logging
    console.log('NotificationsPanel render:', {
      notificationsCount: notifications.length,
      notifications: notifications.map(n => ({
        id: n.id,
        entityName: n.entityName,
        entityId: n.entityId,
        read: n.read,
        timestamp: n.timestamp,
        changes: {
          hasChanges: n.changes?.hasChanges,
          messagePresent: !!n.changes?.message,
          importantUpdatesCount: n.changes?.importantUpdates?.length,
          mediaUpdatesCount: n.changes?.mediaUpdates?.length,
          relatedRiskFactorsCount: n.changes?.relatedRiskFactors?.length,
          addedCount: n.changes?.added?.length,
          removedCount: n.changes?.removed?.length
        }
      }))
    });

    // Log any notifications with missing or malformed data
    const problematicNotifications = notifications.filter(n => {
      return !n.changes || !n.entityName || !n.entityId;
    });

    if (problematicNotifications.length > 0) {
      console.warn('Found notifications with missing data:', problematicNotifications);
    }

    return (
      <div className="fixed inset-0 bg-black/20 z-50">
        <div className="absolute right-0 top-0 h-full w-full max-w-xl bg-white shadow-xl">
          {/* Header */}
          <div className="flex items-center justify-between px-6 py-4 border-b">
            <div className="flex items-center gap-3">
              <Bell className="w-5 h-5 text-gray-600" />
              <h3 className="text-lg font-semibold text-gray-900">Notifications</h3>
            </div>
            <div className="flex items-center">
              {notifications.length > 0 && (
                <AlertDialog>
                  <AlertDialogTrigger asChild>
                    <button
                      className="p-2 hover:bg-gray-100 rounded-full transition-colors text-red-500"
                      title="Delete all notifications"
                    >
                      <Trash2 className="w-5 h-5" />
                    </button>
                  </AlertDialogTrigger>
                  <AlertDialogContent onPointerDownOutside={(e) => e.preventDefault()}>
                    <AlertDialogHeader>
                      <AlertDialogTitle>Delete All Notifications</AlertDialogTitle>
                      <AlertDialogDescription>
                        This will permanently delete all your notifications. This action cannot be undone.
                      </AlertDialogDescription>
                    </AlertDialogHeader>
                    <AlertDialogFooter>
                      <AlertDialogCancel onPointerDown={(e) => e.preventDefault()}>
                        Cancel
                      </AlertDialogCancel>
                      <AlertDialogAction
                        onClick={deleteAllNotifications}
                        className="bg-red-600 hover:bg-red-700 focus:ring-red-600 text-white"
                      >
                        Delete All
                      </AlertDialogAction>
                    </AlertDialogFooter>
                  </AlertDialogContent>
                </AlertDialog>
              )}
              <button
                onClick={markAllNotificationsAsRead}
                className="p-2 hover:bg-gray-100 rounded-full transition-colors"
                title="Mark all as read"
              >
                <Check className="w-5 h-5 text-gray-500" />
              </button>
              <button 
                onClick={() => setShowNotifications(false)}
                className="p-2 hover:bg-gray-100 rounded-full transition-colors"
              >
                <X className="w-5 h-5 text-gray-500" />
              </button>
            </div>
          </div>

          {/* Notifications List */}
          <div className="overflow-y-auto h-[calc(100vh-73px)]">
            {notifications.length === 0 ? (
              <div className="h-full flex flex-col items-center justify-center text-center p-4">
                <Bell className="w-12 h-12 text-gray-400 mb-2" />
                <h3 className="text-sm font-medium text-gray-900">No new notifications</h3>
                <p className="mt-1 text-sm text-gray-500">
                  When there are updates to your monitored entities, they will appear here
                </p>
              </div>
            ) : (
              notifications.map((notification) => {
                // Add debug logging for each notification
                console.log('Processing notification:', {
                  id: notification.id,
                  entityName: notification.entityName,
                  hasChanges: notification.changes?.hasChanges,
                  importantUpdatesCount: notification.changes?.importantUpdates?.length,
                  importantUpdates: notification.changes?.importantUpdates,
                  mediaUpdatesCount: notification.changes?.mediaUpdates?.length,
                  riskFactorsCount: notification.changes?.relatedRiskFactors?.length,
                  addedCount: notification.changes?.added?.length,
                  removedCount: notification.changes?.removed?.length,
                  timestamp: notification.timestamp
                });

                return (
                  <div 
                    key={notification.id}
                    onClick={() => {
                      markNotificationAsRead(notification.id);
                      if (monitoredItems) {
                        const monitoredItem = monitoredItems.find(
                          item => item.id === notification.entityId
                        );
                        if (monitoredItem) {
                          clearAlertsForItem(monitoredItem.id);
                          setShowNotifications(false);
                          onMonitorItemClick(monitoredItem);
                        }
                      }
                    }}
                    className="border-b last:border-b-0 hover:bg-gray-100 transition-colors cursor-pointer"
                  >
                    <div className="px-6 py-4">
                      <div className="flex items-start gap-4">
                        <div className="flex-shrink-0 mt-1">
                          <AlertCircle className={`w-5 h-5 ${
                            !notification.read 
                              ? 'text-amber-500'  // Show amber for unread
                              : 'text-gray-400'   // Show gray for read
                          }`} />
                        </div>
                        <div className="flex-1 min-w-0">
                          <h4 className="text-sm font-medium text-gray-900">
                            Updates for {notification.entityName || monitoredItems.find(
                              item => item.id === notification.entityId
                            )?.individualName || monitoredItems.find(
                              item => item.id === notification.entityId
                            )?.companyName}
                          </h4>
                          
                          <p className="text-xs text-gray-500 mt-1">
                            {notification.timestamp instanceof Date 
                              ? notification.timestamp.toLocaleString()
                              : notification.timestamp?.seconds 
                                ? new Date(notification.timestamp.seconds * 1000).toLocaleString()
                                : 'Unknown date'
                            }
                          </p>

                          <div className="mt-3 space-y-3">
                            {/* Debug section for development */}
                            {process.env.NODE_ENV === 'development' && (
                              <pre className="text-xs text-gray-500 whitespace-pre-wrap">
                                {JSON.stringify({
                                  hasImportantUpdates: notification.changes?.importantUpdates?.length > 0,
                                  importantUpdates: notification.changes?.importantUpdates,
                                  changes: notification.changes
                                }, null, 2)}
                              </pre>
                            )}

                            {/* Risk Updates section */}
                            {notification.changes?.relatedRiskFactors?.length > 0 && (
                              <div className="rounded-md bg-orange-50 p-3">
                                <h5 className="text-sm font-medium text-orange-800 mb-2">New Risk Factors:</h5>
                                <ul className="list-disc pl-4 text-sm text-orange-700">
                                  {notification.changes.relatedRiskFactors
                                    .map(cleanAndTransformTag)
                                    .filter(Boolean)
                                    .map((tag, idx) => (
                                      <li key={idx}>{tag}</li>
                                    ))}
                                </ul>
                              </div>
                            )}

                            {/* Important Updates */}
                            {(notification.changes?.significantChanges?.length > 0 || 
                              notification.changes?.importantUpdates?.length > 0) && (
                              <div className="rounded-md bg-yellow-50 p-3">
                                <h5 className="text-sm font-medium text-yellow-800 mb-2">Important Updates:</h5>
                                <ul className="list-disc pl-4 text-sm text-yellow-700">
                                  {[
                                    ...(notification.changes.significantChanges || []),
                                    ...(notification.changes.importantUpdates || [])
                                  ].map((update, idx) => (
                                    <li key={idx}>{update}</li>
                                  ))}
                                </ul>
                              </div>
                            )}

                            {/* Sanctions changes */}
                            {notification.changes.added?.length > 0 && (
                              <div className="rounded-md bg-red-50 p-3">
                                <h5 className="text-sm font-medium text-red-800 mb-2">New Sanctions Matches:</h5>
                                <ul className="list-disc pl-4 text-sm text-red-700">
                                  {notification.changes.added.map((match, idx) => (
                                    <li key={idx}>{match.caption || match.name}</li>
                                  ))}
                                </ul>
                              </div>
                            )}

                            {notification.changes.removed?.length > 0 && (
                              <div className="rounded-md bg-green-50 p-3">
                                <h5 className="text-sm font-medium text-green-800 mb-2">Removed Sanctions Matches:</h5>
                                <ul className="list-disc pl-4 text-sm text-green-700">
                                  {notification.changes.removed.map((match, idx) => (
                                    <li key={idx}>{match.caption || match.name}</li>
                                  ))}
                                </ul>
                              </div>
                            )}

                            {/* Media Updates */}
                            {notification.changes?.mediaUpdates?.length > 0 && 
                              notification.changes.mediaUpdates[0]?.mediaChanges?.added?.length > 0 && (
                              <div className="rounded-md bg-blue-50 p-3">
                                <h5 className="text-sm font-medium text-blue-800 mb-2">New Media Coverage:</h5>
                                <ul className="space-y-2">
                                  {notification.changes.mediaUpdates[0].mediaChanges.added.map((source, idx) => (
                                    <li key={idx} className="text-sm">
                                      <a 
                                        href={source.url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        onClick={(e) => e.stopPropagation()}
                                        className="text-blue-600 hover:text-blue-800 hover:underline"
                                      >
                                        {source.title}
                                      </a>
                                      {source.date && (
                                        <span className="text-blue-700 text-xs ml-2">
                                          ({new Date(source.date).toLocaleDateString()})
                                        </span>
                                      )}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            )}

                            {/* No Changes Message */}
                            {notification.changes.hasChanges === false && (
                              <p className="text-sm text-gray-600">
                                {notification.changes.message}
                              </p>
                            )}

                            {/* Debug output during development */}
                            {process.env.NODE_ENV === 'development' && (
                              <pre className="text-xs text-gray-500 mt-2">
                                {JSON.stringify({
                                  id: notification.id,
                                  changes: notification.changes,
                                  timestamp: notification.timestamp
                                }, null, 2)}
                              </pre>
                            )}

                            {/* Debug Information */}
                            {process.env.NODE_ENV === 'development' && (
                              <pre className="mt-4 text-xs text-gray-500 whitespace-pre-wrap">
                                {JSON.stringify({
                                  notificationData: notification,
                                  changes: notification.changes,
                                  mediaUpdates: notification.changes?.mediaUpdates
                                }, null, 2)}
                              </pre>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </div>
    );
  };

  const getLastCheckedDate = (item) => {
    // console.log('Last checked data:', {
    //   lastChecked: item.lastChecked,
    //   seconds: item.lastChecked?.seconds,
    //   type: item.lastChecked ? typeof item.lastChecked : 'undefined'
    // });
    
    if (!item.lastChecked?.seconds) return 'Never';
    try {
      return new Date(item.lastChecked.seconds * 1000).toLocaleDateString();
    } catch (error) {
      console.error('Error formatting lastChecked date:', error);
      return 'Date error';
    }
  };

  // Add a helper function to check for unread notifications
  const hasUnreadNotifications = (itemId) => {
    return notifications.some(notification => 
      notification.entityId === itemId && !notification.read
    );
  };

  // Add this function before the return statement
  const handleClearMonitoring = async () => {
    try {
      const toastId = toast.loading('Clearing monitored entities...');
      const batch = writeBatch(db);
      
      // Get all monitoring documents
      const monitoringRef = collection(db, 'users', user.uid, 'monitoring');
      const monitoringDocs = await getDocs(monitoringRef);
      
      // Get all notifications
      const notificationsRef = collection(db, 'users', user.uid, 'notifications');
      const notificationsDocs = await getDocs(notificationsRef);
      
      // Add all monitoring documents to batch delete
      monitoringDocs.forEach(doc => {
        batch.delete(doc.ref);
      });

      // Add all notifications to batch delete
      notificationsDocs.forEach(doc => {
        batch.delete(doc.ref);
      });

      // Commit the batch
      await batch.commit();
      
      // Update local state
      setMonitoredItems([]);
      setNotifications([]);
      toast.success('All monitored entities cleared', { id: toastId });
    } catch (error) {
      console.error('Error clearing monitoring:', error);
      toast.error('Failed to clear monitored entities');
    }
  };

  // Add escape key handler for notifications panel
  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === 'Escape' && showNotifications) {
        setShowNotifications(false);
      }
    };

    document.addEventListener('keydown', handleEscape);
    return () => document.removeEventListener('keydown', handleEscape);
  }, [showNotifications]);

  // Update notifications fetch to use real-time listener
  useEffect(() => {
    if (!user?.uid) return;

    const notificationsQuery = query(
      collection(db, 'users', user.uid, 'notifications'),
      orderBy('timestamp', 'desc')
    );

    const unsubscribe = onSnapshot(notificationsQuery, {
      next: (snapshot) => {
        const newNotifications = snapshot.docs.map(doc => {
          try {
            const data = doc.data();
            
            return {
              id: doc.id,
              ...data,
              entityName: data.entityName || 'Unknown Entity',
              entityId: data.entityId,
              read: Boolean(data.read),
              changes: data.changes || {}, // Preserve original changes structure
              timestamp: data.timestamp?.toDate?.() || new Date()
            };
          } catch (error) {
            console.error('Error processing notification:', doc.id, error);
            return null;
          }
        }).filter(Boolean);

        setNotifications(newNotifications);
      },
      error: (error) => {
        console.error('Error in notifications subscription:', error);
        toast.error('Error loading notifications');
      }
    });

    return () => unsubscribe();
  }, [user?.uid]);

  // Add new debugging effect for notifications
  useEffect(() => {
    // console.log('Notifications state updated:', {
    //   count: notifications.length,
    //   notifications: notifications.map(n => ({
    //     id: n.id,
    //     hasChanges: n.changes?.hasChanges,
    //     significantChanges: n.changes?.significantChanges,
    //     riskUpdates: n.changes?.riskUpdates,
    //     mediaUpdates: n.changes?.mediaUpdates
    //   }))
    // });
  }, [notifications]);

  const markAllNotificationsAsRead = async () => {
    try {
      const batch = writeBatch(db);
      const unreadNotificationsQuery = query(
        collection(db, 'users', user.uid, 'notifications'),
        where('read', '==', false)
      );
      
      const unreadNotifications = await getDocs(unreadNotificationsQuery);
      
      unreadNotifications.forEach((doc) => {
        batch.update(doc.ref, { read: true });
      });
      
      await batch.commit();
      
      // Update monitored items to clear alert counts
      const monitoringDocs = await getDocs(
        collection(db, 'users', user.uid, 'monitoring')
      );
      
      const alertBatch = writeBatch(db);
      monitoringDocs.forEach((doc) => {
        alertBatch.update(doc.ref, { alerts: 0 });
      });
      
      await alertBatch.commit();
      await fetchData();
    } catch (error) {
      console.error('Error marking all notifications as read:', error);
    }
  };

  const handleMonitorItemClick = async (item) => {
    // console.log('Handling monitor item click:', {
    //   itemId: item.id,
    //   results: item.results
    // });

    // When clicking a monitored item, pass its comments, conclusion, and monitoring ID
    onMonitorItemClick({
      ...item,
      comments: item.comments || {},
      conclusion: item.conclusion || '',
      results: item.results?.map(result => ({
        ...result,
        monitoringId: item.id,
        comments: item.comments || {},
        conclusion: item.conclusion || ''
      }))
    });
  };

  // Update the deleteAllNotifications function
  const deleteAllNotifications = async () => {
    try {
      const toastId = toast.loading('Deleting all notifications...');
      
      const idToken = await auth.currentUser.getIdToken();
      const response = await fetch(`${API_URL}/api/delete-all-notifications`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${idToken}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to delete notifications');
      }

      // Wait for response completion before updating state
      await response.json();
      
      // Update local state
      setNotifications([]);
      // setShowNotifications(false); // Close notifications panel
      await fetchData(); // Refresh monitoring items to show updated alert counts
      
      toast.success('All notifications deleted', { id: toastId });
    } catch (error) {
      console.error('Error deleting all notifications:', error);
      toast.error('Failed to delete notifications');
    }
  };

  return (
    <div className="bg-white rounded-2xl shadow-sm border p-6">
      <div className="opacity-0 animate-fade-in">
        {/* Header with responsive spacing */}
        <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between mb-6">
          <div className="flex items-center mb-4 sm:mb-0">
            <h2 className="text-2xl font-semibold">Monitored Entities</h2>
            {notifications.length > 0 && (
              <button 
                onClick={() => setShowNotifications(!showNotifications)}
                className={`relative ml-2 flex items-center justify-center w-8 h-8 rounded-full transition-colors ${
                  notifications.filter(n => !n.read).length > 0 
                    ? 'bg-red-50 ring-2 ring-red-500 hover:bg-red-100' 
                    : 'bg-gray-100 hover:bg-gray-200'
                } focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2`}
              >
                <Bell className={`w-4 h-4 ${
                  notifications.filter(n => !n.read).length > 0 ? 'text-red-600' : 'text-gray-600'
                }`} />
                {notifications.filter(n => !n.read).length > 0 && (
                  <span className={`absolute -top-2 -right-2 bg-red-500 text-white text-xs rounded-full min-w-[20px] h-5 flex items-center justify-center px-1 ${
                    notifications.filter(n => !n.read).length > 99 ? 'text-[10px]' : 'text-xs'
                  }`}>
                    {notifications.filter(n => !n.read).length > 99 ? '99+' : notifications.filter(n => !n.read).length}
                  </span>
                )}
              </button>
            )}
            {monitoredItems.length > 0 && (
              <AlertDialog>
                <AlertDialogTrigger asChild>
                  <button 
                    className="relative ml-2 flex items-center justify-center w-8 h-8 rounded-full bg-red-50 hover:bg-red-100 focus:outline-none focus:ring-0"
                  >
                    <Trash2 className="w-4 h-4 text-red-600" />
                  </button>
                </AlertDialogTrigger>
                <AlertDialogContent onPointerDownOutside={(e) => e.preventDefault()}>
                  <AlertDialogHeader>
                    <AlertDialogTitle>Clear All Monitored Entities</AlertDialogTitle>
                    <AlertDialogDescription>
                      This will permanently delete all your monitored entities and their notifications. This action cannot be undone.
                    </AlertDialogDescription>
                  </AlertDialogHeader>
                  <AlertDialogFooter>
                    <AlertDialogCancel 
                      onPointerDown={(e) => e.preventDefault()}
                    >
                      Cancel
                    </AlertDialogCancel>
                    <AlertDialogAction
                      onClick={handleClearMonitoring}
                      className="bg-red-600 hover:bg-red-700 focus:ring-red-600 text-white"
                    >
                      Clear All
                    </AlertDialogAction>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialog>
            )}
          </div>
          
          {/* Filter section - updated with search bar */}
          <div className="flex flex-col sm:flex-row gap-2 w-full sm:w-auto">
            <SearchFilter 
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder="Search"
              isLoading={isSearchLoading}
              className="border-gray-200"
            />
            <div className="flex gap-2 overflow-x-auto sm:overflow-visible">
              <button
                onClick={() => handleFilterChange('all')}
                className={`flex-none px-4 py-2 rounded-lg text-sm ${
                  filter === 'all' 
                    ? 'bg-gray-100 text-gray-900'
                    : 'text-gray-600 hover:bg-gray-50'
                }`}
              >
                All
              </button>
              <button
                onClick={() => handleFilterChange('individual')}
                className={`flex-none px-4 py-2 rounded-lg text-sm ${
                  filter === 'individual'
                    ? 'bg-gray-100 text-gray-900'
                    : 'text-gray-600 hover:bg-gray-50'
                }`}
              >
                Individuals
              </button>
              <button
                onClick={() => handleFilterChange('company')}
                className={`flex-none px-4 py-2 rounded-lg text-sm ${
                  filter === 'company'
                    ? 'bg-gray-100 text-gray-900'
                    : 'text-gray-600 hover:bg-gray-50'
                }`}
              >
                Companies
              </button>
            </div>
          </div>
        </div>

        {/* Monitored Items */}
        <div className="-mx-6 sm:mx-0">
          <div className="space-y-3">
            {/* Only show loading state on initial page load, not during search */}
            {loading && !isSearchMode ? (
              <div className="space-y-4">
                {[1, 2, 3].map((i) => (
                  <div key={i} className="animate-pulse">
                    <div className="flex items-center p-4 sm:p-4 border-b sm:border sm:rounded-xl">
                      {/* Icon skeleton */}
                      <div className="flex-shrink-0">
                        <div className="w-10 h-10 bg-gray-200 rounded-full"></div>
                      </div>
                      {/* Content skeleton */}
                      <div className="ml-4 flex-1">
                        <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                        <div className="mt-2 space-y-2">
                          <div className="h-3 bg-gray-200 rounded w-1/4"></div>
                          <div className="h-3 bg-gray-200 rounded w-1/2"></div>
                        </div>
                      </div>
                      {/* Actions skeleton */}
                      <div className="flex items-center gap-2">
                        <div className="w-8 h-8 bg-gray-200 rounded-full"></div>
                        <div className="w-5 h-5 bg-gray-200 rounded"></div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : displayedItems.length === 0 ? (
              <div className="text-center py-8">
                <Bell className="mx-auto h-12 w-12 text-gray-400" />
                <h3 className="mt-2 text-sm font-semibold text-gray-900">No monitored entities yet</h3>
                <p className="mt-1 text-sm text-gray-500 max-w-[250px] mx-auto">
                  Run a search and click "Monitor" to start tracking an entity
                </p>
              </div>
            ) : (
              displayedItems.map((item) => {
                
                // console.log('Monitoring Item Results:', {
                //   searchType: item.searchType,
                //   name: item.individualName || item.companyName,
                //   results: item.results.map(r => ({
                //     id: r.id,
                //     name: r.name,
                //     caption: r.caption,
                //     formattedTitle: getResultTitle(r)
                //   }))
                // });
                
                // Add debugging logs for dates
                // console.log('Monitored item dates:', {
                //   id: item.id,
                //   name: item.individualName || item.companyName,
                //   timestamp: item.timestamp?.seconds ? new Date(item.timestamp.seconds * 1000) : null,
                //   lastChecked: item.lastChecked?.seconds ? new Date(item.lastChecked.seconds * 1000) : null
                // });
                
                return (
                  <div
                    key={item.id}
                    className="w-full flex items-center p-4 sm:p-4 hover:bg-gray-100 transition-colors text-left border-b sm:border sm:rounded-xl"
                  >
                    {/* Icon - Now with logging to debug */}
                    <div className="flex-shrink-0">
                      {item.searchType === 'individual' ? (
                        <User className="w-10 h-10 text-gray-400" />
                      ) : (
                        <Building2 className="w-10 h-10 text-gray-400" />
                      )}
                    </div>

                    {/* Content */}
                    <div 
                      className="ml-4 flex-1 min-w-0 cursor-pointer" 
                      onClick={() => {
                        markAllNotificationsAsReadForItem(item.id);
                        clearAlertsForItem(item.id);
                        handleMonitorItemClick(item);
                      }}
                    >
                      <div className="flex items-center space-x-2">
                        <h3 className="text-base sm:text-lg font-medium text-gray-900 truncate">
                          {item.searchType === 'individual' ? item.individualName : item.companyName}
                        </h3>
                        {hasUnreadNotifications(item.id) && (
                          <AlertCircle className="w-5 h-5 text-amber-500" />
                        )}
                      </div>
                      <div className="flex flex-wrap items-center gap-2 mt-1 text-sm text-gray-500">
                        <span>Frequency: {formatFrequency(item.frequency)}</span>
                        <span>Last checked: {getLastCheckedDate(item)}</span>
                        <span className="px-2 py-1 bg-gray-100 rounded-lg truncate">
                          {getDisplayCountry(item.country)}
                        </span>
                        {item.alerts > 0 && (
                          <span className="flex items-center text-red-600">
                            ⚠️ {item.alerts} alerts
                          </span>
                        )}
                      </div>
                    </div>

                    {/* Menu and Chevron */}
                    <MonitoringMenu 
                      item={item} 
                      onRefresh={fetchData}
                    />
                    <div 
                      className="cursor-pointer"
                      onClick={() => {
                        markAllNotificationsAsReadForItem(item.id);
                        clearAlertsForItem(item.id);
                        handleMonitorItemClick(item);
                      }}
                    >
                      <ChevronRight className="h-5 w-5 text-gray-400 flex-shrink-0 ml-4" />
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>

        {/* Add Load More button */}
        {!loading && displayedItems.length > 0 && hasMore && (
          <div className="flex justify-center py-4">
            <button
              onClick={loadMore}
              disabled={isLoadingMore}
              className="px-4 py-2 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200 disabled:opacity-50"
            >
              {isLoadingMore ? 'Loading...' : 'Load More'}
            </button>
          </div>
        )}

        {/* Add notifications panel */}
        <NotificationsPanel />
      </div>
    </div>
  );

};

export default MonitoringDashboard;