// src/services/api.js
import { auth, db } from '../config/firebase';
import { 
  getFirestore,
  collection as firestoreCollection,
  addDoc, 
  serverTimestamp, 
  doc, 
  setDoc,
  getDocs,
  writeBatch,
  Timestamp,
  getDoc,
  query,
  where
} from 'firebase/firestore';
import { formatName } from '../utils/formatters';
import { toast } from 'react-hot-toast';

export const saveSearchHistory = async (userId, searchData, searchResults) => {
  try {
    // First create a clean version of results without undefined values
    const cleanResults = searchResults.map(result => {
      // Create a clean copy of the result object
      const cleanResult = {};
      
      // Only copy defined values
      Object.keys(result).forEach(key => {
        if (result[key] !== undefined) {
          cleanResult[key] = result[key];
        }
      });
      
      return cleanResult;
    });

    // Create the document
    const docRef = await addDoc(firestoreCollection(db, 'users', userId, 'searches'), {
      timestamp: serverTimestamp(),
      searchType: searchData.searchType,
      individualName: searchData.individualName ? formatName(searchData.individualName) : null,
      companyName: searchData.companyName ? formatName(searchData.companyName) : null,
      organization: searchData.organization ? formatName(searchData.organization) : null,
      designation: searchData.designation ? formatName(searchData.designation) : null,
      dob: searchData.dob || null,
      gender: searchData.gender || null,
      country: searchData.country || null,
      matchThreshold: searchData.matchThreshold || null,
      alerts: searchResults.filter(r => r.properties?.topics?.length > 0).length,
      results: cleanResults
    });

    return docRef.id;
  } catch (error) {
    console.error('Error saving search history:', error);
    throw error;
  }
};

export const API_URL = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1'
  ? process.env.REACT_APP_EMULATOR_BACKEND_URL
  : process.env.REACT_APP_CLOUD_FUNCTION_URL;

// Add request deduplication
let currentRequest = null;

// Get the current user's ID token
const getIdToken = async () => {
  const user = auth.currentUser;
  
  if (!user) {
    throw new Error('User not authenticated');
  }
  
  try {
    // Force token refresh
    await user.reload();
    const token = await user.getIdToken(true);
    return token;
  } catch (error) {
    console.error('Error getting ID token:', error);
    // Check if error is due to verification
    if (error.code === 'auth/requires-recent-login') {
      // Redirect to verification page
      window.location.href = '/verify';
    }
    throw new Error('Failed to get authentication token');
  }
};

// Main search API function - single endpoint
export const searchApi = async (searchData) => {
  try {
    // If there's already a request in progress, cancel it
    if (currentRequest) {
      console.log('Canceling duplicate request');
      return currentRequest;
    }

    // Get the authentication token
    const idToken = await getIdToken();

    // Create the new request
    currentRequest = (async () => {
      try {
        const response = await fetch(`${API_URL}/search`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${idToken}`
          },
          body: JSON.stringify(searchData)
        });
        
        if (response.status === 429) {
          toast.error('Daily limit reached. Please try again tomorrow.');
          throw new Error('Daily limit reached');
        }

        if (!response.ok) {
          const errorText = await response.text();
          console.error('API Error response:', errorText);
          throw new Error(errorText || 'API request failed');
        }

        const responseData = await response.json();

        // Save search history after successful search and get the ID
        let searchId = null;
        if (auth.currentUser && responseData.results?.length > 0) {
          try {
            searchId = await saveSearchHistory(auth.currentUser.uid, searchData, responseData.results);
            console.log('Search history saved with ID:', searchId); // Debug log
            
            // Add the search ID to each result
            responseData.results = responseData.results.map(result => ({
              ...result,
              searchHistoryId: searchId,
              id: result.id || `${searchId}_${Math.random().toString(36).substr(2, 9)}`
            }));
          } catch (error) {
            console.error('Error saving search history:', error);
          }
        }

        return responseData;
      } finally {
        // Clear the current request when done
        currentRequest = null;
      }
    })();

    return await currentRequest;
  } catch (error) {
    console.error('API call error:', error);
    if (error.message !== 'Daily limit reached') {
      toast.error('Sorry, something went wrong. Please try again later.');
    }
    throw error;
  }
};

export const saveMonitoredEntity = async (userId, data) => {
  try {
    const token = await auth.currentUser.getIdToken();
    const response = await fetch(`${API_URL}/api/save-monitored-entity`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || 'Failed to save monitored entity');
    }

    return response.json();
  } catch (error) {
    console.error('Error saving monitored entity:', error);
    throw error;
  }
};

export const checkApiLimits = async (userId) => {
  try {
    const token = await auth.currentUser.getIdToken(true);
    const response = await fetch(`${API_URL}/api/user-stats`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || 'Failed to fetch user stats');
    }

    const data = await response.json();
    
    // Return a consistent structure that matches what SearchResults.js expects
    return {
      isLimited: data.isLimited,
      searchesRemaining: data.searchesRemaining || {
        daily: data.limits[data.isSubscribed ? 'paid' : 'free'].DAILY_SEARCHES - data.currentUsage.dailySearches,
        total: data.isSubscribed ? null : data.limits.free.TOTAL_SEARCHES - data.currentUsage.totalSearches
      },
      monitoringRemaining: data.monitoringRemaining || 
        (data.limits[data.isSubscribed ? 'paid' : 'free'].MONITORED_ENTITIES - data.currentUsage.monitoredEntities),
      isSubscribed: data.isSubscribed
    };
  } catch (error) {
    console.error('Error checking API limits:', error);
    throw error;
  }
};

const fetchWithRetry = async (url, options, maxRetries = 3) => {
  let lastError;
  
  for (let i = 0; i < maxRetries; i++) {
    try {
      const response = await fetch(url, {
        ...options,
        timeout: 5000, // 5 second timeout
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      return response;
    } catch (error) {
      console.error(`Attempt ${i + 1} failed:`, error);
      lastError = error;
      
      // Wait before retrying (exponential backoff)
      if (i < maxRetries - 1) {
        await new Promise(resolve => setTimeout(resolve, Math.pow(2, i) * 1000));
      }
    }
  }
  
  throw lastError;
};

export const loadUserPreferences = async () => {
  const defaultPrefs = {
    defaultMatchThreshold: 75,
    defaultCountry: '🇺🇸 United States'
  };

  if (!auth.currentUser) {
    return defaultPrefs;
  }

  try {
    const token = await getIdToken();
    const response = await fetchWithRetry(`${API_URL}/api/user-preferences`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Accept': 'application/json'
      }
    });

    const data = await response.json();
    return {
      defaultMatchThreshold: data.defaultMatchThreshold || defaultPrefs.defaultMatchThreshold,
      defaultCountry: data.defaultCountry || defaultPrefs.defaultCountry
    };
  } catch (error) {
    console.error('Error loading preferences:', error);
    return defaultPrefs;
  }
};

export const saveUserPreferences = async (preferences) => {
  if (!auth.currentUser) {
    throw new Error('User not authenticated');
  }

  if (!preferences || typeof preferences !== 'object') {
    throw new Error('Invalid preferences format');
  }

  const token = await auth.currentUser.getIdToken(true);
  const response = await fetch(`${API_URL}/api/user-preferences`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      defaultMatchThreshold: preferences.defaultMatchThreshold,
      defaultCountry: preferences.defaultCountry
    })
  });

  if (!response.ok) {
    const errorText = await response.text();
    throw new Error(`Failed to save preferences: ${errorText}`);
  }

  return response.json();
};

export const checkVerificationStatus = (user) => {
  if (!user) return false;
  
  const isEmulator = process.env.NODE_ENV === 'development' || 
                    window.location.hostname === 'localhost' || 
                    window.location.hostname === '127.0.0.1';
  
  const emailVerified = isEmulator 
    ? localStorage.getItem(`user_${user.uid}_emailVerified`) === 'true'
    : user.emailVerified;
  
  const phoneVerified = isEmulator
    ? localStorage.getItem(`user_${user.uid}_phoneVerified`) === 'true'
    : Boolean(user.phoneNumber);
    
  return emailVerified && phoneVerified;
};

export const waitForAuthInit = () => {
  return new Promise((resolve) => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      unsubscribe();
      resolve(user);
    });
  });
};