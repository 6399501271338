import React, { useState } from 'react';
import { X } from 'lucide-react';
import { auth } from '../config/firebase';
import { 
  Card, 
  CardContent, 
  CardHeader, 
  CardTitle,
  CardFooter 
} from '../components/ui/card';
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
  AlertDialogCancel
} from '../components/ui/alert-dialog';
import { toast } from 'react-hot-toast';
import SubscriptionPlansModal from './SubscriptionPlansModal';
import useSubscription from './useSubscription';

const SubscriptionStatus = ({ className = '' }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const { 
    stats, 
    loading, 
    error,
    handleSubscribe: hookHandleSubscribe,
    handleCancelSubscription, 
    handleReactivateSubscription,
    handlePaymentSuccess
  } = useSubscription();

  const handleSubscribeClick = async () => {
    setDialogOpen(false);
    try {
      await hookHandleSubscribe();
    } catch (error) {
      // Error handling is already done in the hook
      console.error('Subscription error:', error);
    }
  };

  // Add this error check before any returns
  if (error) {
    return (
      <button 
        onClick={() => window.location.reload()} 
        className="px-4 py-1.5 bg-red-100 text-red-700 rounded-full hover:bg-red-200 transition-colors text-sm font-medium"
      >
        Retry
      </button>
    );
  }

  if (loading) {
    // Don't render the Upgrade/Pro button until it has loaded fully
    return null;
  }

  return (
    <AlertDialog open={dialogOpen} onOpenChange={setDialogOpen}>
      <AlertDialogTrigger asChild>
        <button 
          className={`hidden md:inline-block px-4 py-1.5 rounded-full transition-colors text-sm font-medium focus:outline-none focus:ring-0 ${
            stats?.isSubscribed 
              ? 'bg-green-100 text-green-700' 
              : 'bg-[#2B82DE] text-white hover:bg-[#2B82DE]/90'
          }`}
        >
          {stats?.isSubscribed ? 'Pro' : 'Upgrade'}
        </button>
      </AlertDialogTrigger>
      {dialogOpen && (
        <SubscriptionPlansModal 
          onClose={() => setDialogOpen(false)}
          stats={stats}
          onSubscribe={handleSubscribeClick}
          onCancel={() => {
            handleCancelSubscription();
            setDialogOpen(false);
          }}
          onReactivate={() => {
            handleReactivateSubscription();
            setDialogOpen(false);
          }}
        />
      )}
    </AlertDialog>
  );
};

export default SubscriptionStatus;