import { useState, useRef, useEffect } from 'react';
import { ChevronDown } from 'lucide-react';
import { COUNTRY_MAPPING } from '../constants/countries';
import { db } from '../config/firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { toast } from 'react-hot-toast';
import { saveUserPreferences } from '../services/api';

const CountrySelect = ({ value, onChange, userId }) => {
  const [focused, setFocused] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [activeIndex, setActiveIndex] = useState(-1);
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);
  const [filteredCountries, setFilteredCountries] = useState(
    Object.entries(COUNTRY_MAPPING).sort(([, nameA], [, nameB]) => {
      const countryA = nameA.split(' ').slice(1).join(' ');
      const countryB = nameB.split(' ').slice(1).join(' ');
      return countryA.localeCompare(countryB);
    })
  );
  const [defaultCountry, setDefaultCountry] = useState('🇺🇸 United States');
  const [isLoadingDefault, setIsLoadingDefault] = useState(true);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setFocused(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    const loadDefaultCountry = async () => {
      if (!userId) {
        setIsLoadingDefault(false);
        return;
      }
      try {
        const userPrefsRef = doc(db, 'users', userId, 'preferences', 'searchSettings');
        const prefsDoc = await getDoc(userPrefsRef);
        if (prefsDoc.exists()) {
          const prefs = prefsDoc.data();
          setDefaultCountry(prefs.defaultCountry);
          if (!value && prefs.defaultCountry) {
            onChange({ target: { value: prefs.defaultCountry } });
          }
        }
      } catch (error) {
        console.error('Error loading default country:', error);
      } finally {
        setIsLoadingDefault(false);
      }
    };
    loadDefaultCountry();
  }, [userId, value, onChange]);

  const handleKeyDown = (e) => {
    if (!focused) return;
    
    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        setActiveIndex(prev => 
          prev < filteredCountries.length - 1 ? prev + 1 : 0
        );
        break;
      case 'ArrowUp':
        e.preventDefault();
        setActiveIndex(prev => 
          prev > 0 ? prev - 1 : filteredCountries.length - 1
        );
        break;
      case 'Enter':
        e.preventDefault();
        if (activeIndex >= 0) {
          handleSelect(filteredCountries[activeIndex][1]);
        }
        break;
      case 'Escape':
        setFocused(false);
        break;
    }
  };

  const handleToggleFocus = (e) => {
    e.preventDefault();
    setFocused((prev) => !prev);
    if (!focused) {
      setSearchTerm('');
      setFilteredCountries(
        Object.entries(COUNTRY_MAPPING).sort(([, nameA], [, nameB]) => {
          const countryA = nameA.split(' ').slice(1).join(' ');
          const countryB = nameB.split(' ').slice(1).join(' ');
          return countryA.localeCompare(countryB);
        })
      );
      setActiveIndex(-1);
    }
  };

  const handleChevronClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setFocused((prev) => !prev);
    if (!focused) {
      setSearchTerm('');
      setFilteredCountries(
        Object.entries(COUNTRY_MAPPING).sort(([, nameA], [, nameB]) => {
          const countryA = nameA.split(' ').slice(1).join(' ');
          const countryB = nameB.split(' ').slice(1).join(' ');
          return countryA.localeCompare(countryB);
        })
      );
    }
  };

  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);
    setActiveIndex(-1);

    const filtered = Object.entries(COUNTRY_MAPPING).filter(([, name]) =>
      name.toLowerCase().includes(term)
    );

    setFilteredCountries(filtered);
  };

  const handleSelect = (name) => {
    onChange({ target: { value: name } });
    setFocused(false);
  };

  const handleBlur = (e) => {
    setTimeout(() => {
      if (!dropdownRef.current?.contains(e.relatedTarget)) {
        setFocused(false);
      }
    }, 100);
  };

  const handleSetDefault = async () => {
    if (!userId || !value) return;
    try {
      await saveUserPreferences({ defaultCountry: value });
      setDefaultCountry(value);
      toast.success('Default country updated');
    } catch (error) {
      console.error('Error setting default country:', error);
      toast.error('Failed to set default country');
    }
  };

  return (
    <div 
      className="relative" 
      ref={dropdownRef} 
      onKeyDown={handleKeyDown}
    >
      <div className="flex gap-2">
        <div
          tabIndex="0"
          className="input-field flex-1 flex justify-between items-center h-12 px-4 py-2 bg-white border rounded-lg shadow-sm cursor-pointer"
          onMouseDown={handleToggleFocus}
          onFocus={handleToggleFocus}
          onBlur={handleBlur}
        >
          <span className={!value ? 'text-transparent' : ''}>
            {value || 'Select Country'}
          </span>
          <div className="flex items-center" onMouseDown={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleToggleFocus(e);
          }}>
            <ChevronDown size={20} className="text-gray-400" />
          </div>
        </div>

        {userId && value && !isLoadingDefault && value !== defaultCountry && (
          <button
            onClick={handleSetDefault}
            className="px-3 py-1 text-sm font-medium text-gray-600 hover:text-gray-900 bg-white border rounded-lg shadow-sm hover:shadow transition-all whitespace-nowrap"
          >
            Set Default
          </button>
        )}
      </div>

      {focused && (
        <div className="absolute z-10 bg-white shadow-md rounded w-full mt-1">
          <input
            ref={inputRef}
            type="text"
            className="w-full px-3 py-2 border-b focus:outline-none"
            placeholder="Type to search..."
            value={searchTerm}
            onChange={handleSearch}
            autoFocus
          />
          <ul 
            className="max-h-60 overflow-auto"
            role="listbox"
          >
            {filteredCountries.map(([code, name], index) => (
              <li
                key={code}
                className={`px-3 py-2 cursor-pointer ${
                  index === activeIndex ? 'bg-gray-100' : 'hover:bg-gray-50'
                }`}
                onClick={() => handleSelect(name)}
                role="option"
                aria-selected={index === activeIndex}
              >
                {name}
              </li>
            ))}
            {filteredCountries.length === 0 && (
              <li className="px-3 py-2 text-gray-500">No results found</li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default CountrySelect;