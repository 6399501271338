export const COUNTRY_MAPPING = {
    "zz": "\u{1F30D} Global",
    "eu": "\u{1F1EA}\u{1F1FA} European Union",
    "un": "\u{1F1FA}\u{1F1F3} United Nations",
    "zr": "\u{1F1E8}\u{1F1E9} Zaire",
    "cz": "\u{1F1E8}\u{1F1FF} Czech Republic",
    "xk": "\u{1F1FD}\u{1F1F0} Kosovo",
    "dd": "\u{1F1E9}\u{1F1EA} East Germany",
    "yucs": "\u{1F1FE}\u{1F1EA} Yugoslavia",
    "csxx": "\u{1F1F7}\u{1F1F8} Serbia and Montenegro",
    "cshh": "\u{1F1E8}\u{1F1FF} Czechoslovakia",
    "suhh": "\u{1F1F7}\u{1F1FA} Soviet Union",
    "ge-ab": "\u{1F3F4} Abkhazia (Occupied Georgia)",
    "x-so": "\u{1F3F4} South Ossetia (Occupied Georgia)",
    "ua-lpr": "\u{1F3F4} Luhansk (Occupied Ukraine)",
    "ua-dpr": "\u{1F3F4} Donetsk (Occupied Ukraine)",
    "ua-cri": "\u{1F3F4} Crimea (Occupied Ukraine)",
    "so-som": "\u{1F3F4} Somaliland",
    "cy-trnc": "\u{1F3F4} Northern Cyprus",
    "az-nk": "\u{1F3F4} Nagorno-Karabakh",
    "iq-kr": "\u{1F3F4} Kurdistan",
    "cn-xz": "\u{1F3F4} Tibet",
    "cq": "\u{1F3F4} Sark",
    "gb-wls": "\u{1F3F4}\u{E0067}\u{E0062}\u{E0077}\u{E006C}\u{E0073}\u{E007F} Wales",
    "gb-sct": "\u{1F3F4}\u{E0067}\u{E0062}\u{E0073}\u{E0063}\u{E0074}\u{E007F} Scotland",
    "gb-nir": "\u{1F1EC}\u{1F1E7} Northern Ireland",
    "md-pmr": "\u{1F3F4} Transnistria (PMR)",
    "pk-km": "\u{1F3F4} Kashmir",
    "ac": "\u{1F1EC}\u{1F1E7} Ascension Island",
    "ad": "\u{1F1E6}\u{1F1E9} Andorra",
    "ae": "\u{1F1E6}\u{1F1EA} United Arab Emirates",
    "af": "\u{1F1E6}\u{1F1EB} Afghanistan",
    "ag": "\u{1F1E6}\u{1F1EC} Antigua & Barbuda",
    "ai": "\u{1F1E6}\u{1F1EE} Anguilla",
    "al": "\u{1F1E6}\u{1F1F1} Albania",
    "am": "\u{1F1E6}\u{1F1F2} Armenia",
    "ao": "\u{1F1E6}\u{1F1F4} Angola",
    "aq": "\u{1F1E6}\u{1F1F6} Antarctica",
    "ar": "\u{1F1E6}\u{1F1F7} Argentina",
    "as": "\u{1F1E6}\u{1F1F8} American Samoa",
    "at": "\u{1F1E6}\u{1F1F9} Austria",
    "au": "\u{1F1E6}\u{1F1FA} Australia",
    "aw": "\u{1F1E6}\u{1F1FC} Aruba",
    "ax": "\u{1F1E6}\u{1F1FD} Åland Islands",
    "az": "\u{1F1E6}\u{1F1FF} Azerbaijan",
    "ba": "\u{1F1E7}\u{1F1E6} Bosnia & Herzegovina",
    "bb": "\u{1F1E7}\u{1F1E7} Barbados",
    "bd": "\u{1F1E7}\u{1F1E9} Bangladesh",
    "be": "\u{1F1E7}\u{1F1EA} Belgium",
    "bf": "\u{1F1E7}\u{1F1EB} Burkina Faso",
    "bg": "\u{1F1E7}\u{1F1EC} Bulgaria",
    "bh": "\u{1F1E7}\u{1F1ED} Bahrain",
    "bi": "\u{1F1E7}\u{1F1EE} Burundi",
    "bj": "\u{1F1E7}\u{1F1EF} Benin",
    "bl": "\u{1F1E7}\u{1F1F1} St. Barthélemy",
    "bm": "\u{1F1E7}\u{1F1F2} Bermuda",
    "bn": "\u{1F1E7}\u{1F1F3} Brunei",
    "bo": "\u{1F1E7}\u{1F1F4} Bolivia",
    "bq": "\u{1F1E7}\u{1F1F6} Caribbean Netherlands",
    "br": "\u{1F1E7}\u{1F1F7} Brazil",
    "bs": "\u{1F1E7}\u{1F1F8} Bahamas",
    "bt": "\u{1F1E7}\u{1F1F9} Bhutan",
    "bv": "\u{1F1E7}\u{1F1FB} Bouvet Island",
    "bw": "\u{1F1E7}\u{1F1FC} Botswana",
    "by": "\u{1F1E7}\u{1F1FE} Belarus",
    "bz": "\u{1F1E7}\u{1F1FF} Belize",
    "ca": "\u{1F1E8}\u{1F1E6} Canada",
    "cc": "\u{1F1E8}\u{1F1E8} Cocos (Keeling) Islands",
    "cd": "\u{1F1E8}\u{1F1E9} Congo - Kinshasa",
    "cf": "\u{1F1E8}\u{1F1EB} Central African Republic",
    "cg": "\u{1F1E8}\u{1F1EC} Congo - Brazzaville",
    "ch": "\u{1F1E8}\u{1F1ED} Switzerland",
    "ci": "\u{1F1E8}\u{1F1EE} Côte d'Ivoire",
    "ck": "\u{1F1E8}\u{1F1F0} Cook Islands",
    "cl": "\u{1F1E8}\u{1F1F1} Chile",
    "cm": "\u{1F1E8}\u{1F1F2} Cameroon",
    "cn": "\u{1F1E8}\u{1F1F3} China",
    "co": "\u{1F1E8}\u{1F1F4} Colombia",
    "cp": "\u{1F1E8}\u{1F1F5} Clipperton Island",
    "cr": "\u{1F1E8}\u{1F1F7} Costa Rica",
    "cu": "\u{1F1E8}\u{1F1FA} Cuba",
    "cv": "\u{1F1E8}\u{1F1FB} Cape Verde",
    "cw": "\u{1F1E8}\u{1F1FC} Curaçao",
    "cx": "\u{1F1E8}\u{1F1FD} Christmas Island",
    "cy": "\u{1F1E8}\u{1F1FE} Cyprus",
    "de": "\u{1F1E9}\u{1F1EA} Germany",
    "dg": "\u{1F1E9}\u{1F1EC} Diego Garcia",
    "dj": "\u{1F1E9}\u{1F1EF} Djibouti",
    "dk": "\u{1F1E9}\u{1F1F0} Denmark",
    "dm": "\u{1F1E9}\u{1F1F2} Dominica",
    "do": "\u{1F1E9}\u{1F1F4} Dominican Republic",
    "dz": "\u{1F1E9}\u{1F1FF} Algeria",
    "ec": "\u{1F1EA}\u{1F1E8} Ecuador",
    "ee": "\u{1F1EA}\u{1F1EA} Estonia",
    "eg": "\u{1F1EA}\u{1F1EC} Egypt",
    "eh": "\u{1F1EA}\u{1F1ED} Western Sahara",
    "er": "\u{1F1EA}\u{1F1F7} Eritrea",
    "es": "\u{1F1EA}\u{1F1F8} Spain",
    "et": "\u{1F1EA}\u{1F1F9} Ethiopia",
    "fi": "\u{1F1EB}\u{1F1EE} Finland",
    "fj": "\u{1F1EB}\u{1F1EF} Fiji",
    "fk": "\u{1F1EB}\u{1F1F0} Falkland Islands",
    "fm": "\u{1F1EB}\u{1F1F2} Micronesia",
    "fo": "\u{1F1EB}\u{1F1F4} Faroe Islands",
    "fr": "\u{1F1EB}\u{1F1F7} France",
    "ga": "\u{1F1EC}\u{1F1E6} Gabon",
    "gb": "\u{1F1EC}\u{1F1E7} United Kingdom",
    "gd": "\u{1F1EC}\u{1F1E9} Grenada",
    "ge": "\u{1F1EC}\u{1F1EA} Georgia",
    "gf": "\u{1F1EC}\u{1F1EB} French Guiana",
    "gg": "\u{1F1EC}\u{1F1EC} Guernsey",
    "gh": "\u{1F1EC}\u{1F1ED} Ghana",
    "gi": "\u{1F1EC}\u{1F1EE} Gibraltar",
    "gl": "\u{1F1EC}\u{1F1F1} Greenland",
    "gm": "\u{1F1EC}\u{1F1F2} Gambia",
    "gn": "\u{1F1EC}\u{1F1F3} Guinea",
    "gp": "\u{1F1EC}\u{1F1F5} Guadeloupe",
    "gq": "\u{1F1EC}\u{1F1F6} Equatorial Guinea",
    "gr": "\u{1F1EC}\u{1F1F7} Greece",
    "gs": "\u{1F1EC}\u{1F1F8} South Georgia & South Sandwich Islands",
    "gt": "\u{1F1EC}\u{1F1F9} Guatemala",
    "gu": "\u{1F1EC}\u{1F1FA} Guam",
    "gw": "\u{1F1EC}\u{1F1FC} Guinea-Bissau",
    "gy": "\u{1F1EC}\u{1F1FE} Guyana",
    "hk": "\u{1F1ED}\u{1F1F0} Hong Kong SAR China",
    "hm": "\u{1F1ED}\u{1F1F2} Heard & McDonald Islands",
    "hn": "\u{1F1ED}\u{1F1F3} Honduras",
    "hr": "\u{1F1ED}\u{1F1F7} Croatia",
    "ht": "\u{1F1ED}\u{1F1F9} Haiti",
    "hu": "\u{1F1ED}\u{1F1FA} Hungary",
    "ic": "\u{1F1EE}\u{1F1E8} Canary Islands",
    "id": "\u{1F1EE}\u{1F1E9} Indonesia",
    "ie": "\u{1F1EE}\u{1F1EA} Ireland",
    "il": "\u{1F1EE}\u{1F1F1} Israel",
    "im": "\u{1F1EE}\u{1F1F2} Isle of Man",
    "in": "\u{1F1EE}\u{1F1F3} India",
    "io": "\u{1F1EE}\u{1F1F4} British Indian Ocean Territory",
    "iq": "\u{1F1EE}\u{1F1F6} Iraq",
    "ir": "\u{1F1EE}\u{1F1F7} Iran",
    "is": "\u{1F1EE}\u{1F1F8} Iceland",
    "it": "\u{1F1EE}\u{1F1F9} Italy",
    "je": "\u{1F1EF}\u{1F1EA} Jersey",
    "jm": "\u{1F1EF}\u{1F1F2} Jamaica",
    "jo": "\u{1F1EF}\u{1F1F4} Jordan",
    "jp": "\u{1F1EF}\u{1F1F5} Japan",
    "ke": "\u{1F1F0}\u{1F1EA} Kenya",
    "kg": "\u{1F1F0}\u{1F1EC} Kyrgyzstan",
    "kh": "\u{1F1F0}\u{1F1ED} Cambodia",
    "ki": "\u{1F1F0}\u{1F1EE} Kiribati",
    "km": "\u{1F1F0}\u{1F1F2} Comoros",
    "kn": "\u{1F1F0}\u{1F1F3} St. Kitts & Nevis",
    "kp": "\u{1F1F0}\u{1F1F5} North Korea",
    "kr": "\u{1F1F0}\u{1F1F7} South Korea",
    "kw": "\u{1F1F0}\u{1F1FC} Kuwait",
    "ky": "\u{1F1F0}\u{1F1FE} Cayman Islands",
    "kz": "\u{1F1F0}\u{1F1FF} Kazakhstan",
    "la": "\u{1F1F1}\u{1F1E6} Laos",
    "lb": "\u{1F1F1}\u{1F1E7} Lebanon",
    "lc": "\u{1F1F1}\u{1F1E8} St. Lucia",
    "li": "\u{1F1F1}\u{1F1EE} Liechtenstein",
    "lk": "\u{1F1F1}\u{1F1F0} Sri Lanka",
    "lr": "\u{1F1F1}\u{1F1F7} Liberia",
    "ls": "\u{1F1F1}\u{1F1F8} Lesotho",
    "lt": "\u{1F1F1}\u{1F1F9} Lithuania",
    "lu": "\u{1F1F1}\u{1F1FA} Luxembourg",
    "lv": "\u{1F1F1}\u{1F1FB} Latvia",
    "ly": "\u{1F1F1}\u{1F1FE} Libya",
    "ma": "\u{1F1F2}\u{1F1E6} Morocco",
    "mc": "\u{1F1F2}\u{1F1E8} Monaco",
    "md": "\u{1F1F2}\u{1F1E9} Moldova",
    "me": "\u{1F1F2}\u{1F1EA} Montenegro",
    "mf": "\u{1F1F2}\u{1F1EB} St. Martin",
    "mg": "\u{1F1F2}\u{1F1EC} Madagascar",
    "mh": "\u{1F1F2}\u{1F1ED} Marshall Islands",
    "mk": "\u{1F1F2}\u{1F1F0} North Macedonia",
    "ml": "\u{1F1F2}\u{1F1F1} Mali",
    "mm": "\u{1F1F2}\u{1F1F2} Myanmar (Burma)",
    "mn": "\u{1F1F2}\u{1F1F3} Mongolia",
    "mo": "\u{1F1F2}\u{1F1F4} Macao SAR China",
    "mp": "\u{1F1F2}\u{1F1F5} Northern Mariana Islands",
    "mq": "\u{1F1F2}\u{1F1F6} Martinique",
    "mr": "\u{1F1F2}\u{1F1F7} Mauritania",
    "ms": "\u{1F1F2}\u{1F1F8} Montserrat",
    "mt": "\u{1F1F2}\u{1F1F9} Malta",
    "mu": "\u{1F1F2}\u{1F1FA} Mauritius",
    "mv": "\u{1F1F2}\u{1F1FB} Maldives",
    "mw": "\u{1F1F2}\u{1F1FC} Malawi",
    "mx": "\u{1F1F2}\u{1F1FD} Mexico",
    "my": "\u{1F1F2}\u{1F1FE} Malaysia",
    "mz": "\u{1F1F2}\u{1F1FF} Mozambique",
    "na": "\u{1F1F3}\u{1F1E6} Namibia",
    "nc": "\u{1F1F3}\u{1F1E8} New Caledonia",
    "ne": "\u{1F1F3}\u{1F1EA} Niger",
    "nf": "\u{1F1F3}\u{1F1EB} Norfolk Island",
    "ng": "\u{1F1F3}\u{1F1EC} Nigeria",
    "ni": "\u{1F1F3}\u{1F1EE} Nicaragua",
    "nl": "\u{1F1F3}\u{1F1F1} Netherlands",
    "no": "\u{1F1F3}\u{1F1F4} Norway",
    "np": "\u{1F1F3}\u{1F1F5} Nepal",
    "nr": "\u{1F1F3}\u{1F1F7} Nauru",
    "nu": "\u{1F1F3}\u{1F1FA} Niue",
    "nz": "\u{1F1F3}\u{1F1FF} New Zealand",
    "om": "\u{1F1F4}\u{1F1F2} Oman",
    "pa": "\u{1F1F5}\u{1F1E6} Panama",
    "pe": "\u{1F1F5}\u{1F1EA} Peru",
    "pf": "\u{1F1F5}\u{1F1EB} French Polynesia",
    "pg": "\u{1F1F5}\u{1F1EC} Papua New Guinea",
    "ph": "\u{1F1F5}\u{1F1ED} Philippines",
    "pk": "\u{1F1F5}\u{1F1F0} Pakistan",
    "pl": "\u{1F1F5}\u{1F1F1} Poland",
    "pm": "\u{1F1F5}\u{1F1F2} St. Pierre & Miquelon",
    "pn": "\u{1F1F5}\u{1F1F3} Pitcairn Islands",
    "pr": "\u{1F1F5}\u{1F1F7} Puerto Rico",
    "ps": "\u{1F1F5}\u{1F1F8} Palestinian Territories",
    "pt": "\u{1F1F5}\u{1F1F9} Portugal",
    "pw": "\u{1F1F5}\u{1F1FC} Palau",
    "py": "\u{1F1F5}\u{1F1FE} Paraguay",
    "qa": "\u{1F1F6}\u{1F1E6} Qatar",
    "re": "\u{1F1F7}\u{1F1EA} Réunion",
    "ro": "\u{1F1F7}\u{1F1F4} Romania",
    "rs": "\u{1F1F7}\u{1F1F8} Serbia",
    "ru": "\u{1F1F7}\u{1F1FA} Russia",
    "rw": "\u{1F1F7}\u{1F1FC} Rwanda",
    "sa": "\u{1F1F8}\u{1F1E6} Saudi Arabia",
    "sb": "\u{1F1F8}\u{1F1E7} Solomon Islands",
    "sc": "\u{1F1F8}\u{1F1E8} Seychelles",
    "sd": "\u{1F1F8}\u{1F1E9} Sudan",
    "se": "\u{1F1F8}\u{1F1EA} Sweden",
    "sg": "\u{1F1F8}\u{1F1EC} Singapore",
    "sh": "\u{1F1F8}\u{1F1ED} St. Helena",
    "si": "\u{1F1F8}\u{1F1EE} Slovenia",
    "sj": "\u{1F1F8}\u{1F1EF} Svalbard & Jan Mayen",
    "sk": "\u{1F1F8}\u{1F1F0} Slovakia",
    "sl": "\u{1F1F8}\u{1F1F1} Sierra Leone",
    "sm": "\u{1F1F8}\u{1F1F2} San Marino",
    "sn": "\u{1F1F8}\u{1F1F3} Senegal",
    "so": "\u{1F1F8}\u{1F1F4} Somalia",
    "sr": "\u{1F1F8}\u{1F1F7} Suriname",
    "ss": "\u{1F1F8}\u{1F1F8} South Sudan",
    "st": "\u{1F1F8}\u{1F1F9} São Tomé & Príncipe",
    "sv": "\u{1F1F8}\u{1F1FB} El Salvador",
    "sx": "\u{1F1F8}\u{1F1FD} Sint Maarten",
    "sy": "\u{1F1F8}\u{1F1FE} Syria",
    "sz": "\u{1F1F8}\u{1F1FF} Eswatini",
    "tc": "\u{1F1F9}\u{1F1E8} Turks & Caicos Islands",
    "td": "\u{1F1F9}\u{1F1E9} Chad",
    "tf": "\u{1F1F9}\u{1F1EB} French Southern Territories",
    "tg": "\u{1F1F9}\u{1F1EC} Togo",
    "th": "\u{1F1F9}\u{1F1ED} Thailand",
    "tj": "\u{1F1F9}\u{1F1EF} Tajikistan",
    "tk": "\u{1F1F9}\u{1F1F0} Tokelau",
    "tl": "\u{1F1F9}\u{1F1F1} Timor-Leste",
    "tm": "\u{1F1F9}\u{1F1F2} Turkmenistan",
    "tn": "\u{1F1F9}\u{1F1F3} Tunisia",
    "to": "\u{1F1F9}\u{1F1F4} Tonga",
    "tr": "\u{1F1F9}\u{1F1F7} Turkey",
    "tt": "\u{1F1F9}\u{1F1F9} Trinidad & Tobago",
    "tv": "\u{1F1F9}\u{1F1FB} Tuvalu",
    "tw": "\u{1F1F9}\u{1F1FC} Taiwan",
    "tz": "\u{1F1F9}\u{1F1FF} Tanzania",
    "ua": "\u{1F1FA}\u{1F1E6} Ukraine",
    "ug": "\u{1F1FA}\u{1F1EC} Uganda",
    "us": "\u{1F1FA}\u{1F1F8} United States",
    "uy": "\u{1F1FA}\u{1F1FE} Uruguay",
    "uz": "\u{1F1FA}\u{1F1FF} Uzbekistan",
    "va": "\u{1F1FB}\u{1F1E6} Vatican City",
    "vc": "\u{1F1FB}\u{1F1E8} St. Vincent & Grenadines",
    "ve": "\u{1F1FB}\u{1F1EA} Venezuela",
    "vg": "\u{1F1FB}\u{1F1EC} British Virgin Islands",
    "vi": "\u{1F1FB}\u{1F1EE} U.S. Virgin Islands",
    "vn": "\u{1F1FB}\u{1F1F3} Vietnam",
    "vu": "\u{1F1FB}\u{1F1FA} Vanuatu",
    "wf": "\u{1F1FC}\u{1F1EB} Wallis & Futuna",
    "ws": "\u{1F1FC}\u{1F1F8} Samoa",
    "xk": "\u{1F1FD}\u{1F1F0} Kosovo",
    "ye": "\u{1F1FE}\u{1F1EA} Yemen",
    "yt": "\u{1F1FE}\u{1F1F9} Mayotte",
    "za": "\u{1F1FF}\u{1F1E6} South Africa",
    "zm": "\u{1F1FF}\u{1F1F2} Zambia",
    "zw": "\u{1F1FF}\u{1F1FC} Zimbabwe"
};

export const phoneCodes = [
    { code: '1', flag: '🇺🇸', name: 'United States' },
    { code: '1', flag: '🇨🇦', name: 'Canada' },
    { code: '213', flag: '🇩🇿', name: 'Algeria' },
    { code: '1684', flag: '🇦🇸', name: 'American Samoa' },
    { code: '376', flag: '🇦🇩', name: 'Andorra' },
    { code: '244', flag: '🇦🇴', name: 'Angola' },
    { code: '672', flag: '🇦🇨', name: 'Antarctica' },
    { code: '54', flag: '🇦🇷', name: 'Argentina' },
    { code: '374', flag: '🇦🇲', name: 'Armenia' },
    { code: '297', flag: '🇦🇼', name: 'Aruba' },
    { code: '61', flag: '🇦🇺', name: 'Australia' },
    { code: '43', flag: '🇦🇹', name: 'Austria' },
    { code: '994', flag: '🇦🇿', name: 'Azerbaijan' },
    { code: '973', flag: '🇧🇭', name: 'Bahrain' },
    { code: '880', flag: '🇧🇩', name: 'Bangladesh' },
    { code: '32', flag: '🇧🇪', name: 'Belgium' },
    { code: '55', flag: '🇧🇷', name: 'Brazil' },
    { code: '86', flag: '🇨🇳', name: 'China' },
    { code: '20', flag: '🇪🇬', name: 'Egypt' },
    { code: '33', flag: '🇫🇷', name: 'France' },
    { code: '49', flag: '🇩🇪', name: 'Germany' },
    { code: '30', flag: '🇬🇷', name: 'Greece' },
    { code: '852', flag: '🇭🇰', name: 'Hong Kong' },
    { code: '36', flag: '🇭🇺', name: 'Hungary' },
    { code: '91', flag: '🇮🇳', name: 'India' },
    { code: '62', flag: '🇮🇩', name: 'Indonesia' },
    { code: '353', flag: '🇮🇪', name: 'Ireland' },
    { code: '972', flag: '🇮🇱', name: 'Israel' },
    { code: '39', flag: '🇮🇹', name: 'Italy' },
    { code: '81', flag: '🇯🇵', name: 'Japan' },
    { code: '82', flag: '🇰🇷', name: 'South Korea' },
    { code: '60', flag: '🇲🇾', name: 'Malaysia' },
    { code: '52', flag: '🇲🇽', name: 'Mexico' },
    { code: '31', flag: '🇳🇱', name: 'Netherlands' },
    { code: '64', flag: '🇳🇿', name: 'New Zealand' },
    { code: '47', flag: '🇳🇴', name: 'Norway' },
    { code: '92', flag: '🇵🇰', name: 'Pakistan' },
    { code: '63', flag: '🇵🇭', name: 'Philippines' },
    { code: '48', flag: '🇵🇱', name: 'Poland' },
    { code: '351', flag: '🇵🇹', name: 'Portugal' },
    { code: '974', flag: '🇶🇦', name: 'Qatar' },
    { code: '7', flag: '🇷🇺', name: 'Russia' },
    { code: '966', flag: '🇸🇦', name: 'Saudi Arabia' },
    { code: '65', flag: '🇸🇬', name: 'Singapore' },
    { code: '27', flag: '🇿🇦', name: 'South Africa' },
    { code: '34', flag: '🇪🇸', name: 'Spain' },
    { code: '46', flag: '🇸🇪', name: 'Sweden' },
    { code: '41', flag: '🇨🇭', name: 'Switzerland' },
    { code: '886', flag: '🇹🇼', name: 'Taiwan' },
    { code: '66', flag: '🇹🇭', name: 'Thailand' },
    { code: '90', flag: '🇹🇷', name: 'Turkey' },
    { code: '380', flag: '🇺🇦', name: 'Ukraine' },
    { code: '971', flag: '🇦🇪', name: 'United Arab Emirates' },
    { code: '44', flag: '🇬🇧', name: 'United Kingdom' },
    { code: '84', flag: '🇻🇳', name: 'Vietnam' },
    { code: '93', flag: '🇦🇫', name: 'Afghanistan' },
    { code: '355', flag: '🇦🇱', name: 'Albania' },
    { code: '1268', flag: '🇦🇬', name: 'Antigua and Barbuda' },
    { code: '591', flag: '🇧🇴', name: 'Bolivia' },
    { code: '387', flag: '🇧🇦', name: 'Bosnia and Herzegovina' },
    { code: '267', flag: '🇧🇼', name: 'Botswana' },
    { code: '359', flag: '🇧🇬', name: 'Bulgaria' },
    { code: '226', flag: '🇧🇫', name: 'Burkina Faso' },
    { code: '257', flag: '🇧🇮', name: 'Burundi' },
    { code: '855', flag: '🇰🇭', name: 'Cambodia' },
    { code: '237', flag: '🇨🇲', name: 'Cameroon' },
    { code: '236', flag: '🇨🇫', name: 'Central African Republic' },
    { code: '235', flag: '🇹🇩', name: 'Chad' },
    { code: '56', flag: '🇨🇱', name: 'Chile' },
    { code: '57', flag: '🇨🇴', name: 'Colombia' },
    { code: '243', flag: '🇨🇩', name: 'Democratic Republic of the Congo' },
    { code: '242', flag: '🇨🇬', name: 'Republic of the Congo' },
    { code: '506', flag: '🇨🇷', name: 'Costa Rica' },
    { code: '385', flag: '🇭🇷', name: 'Croatia' },
    { code: '53', flag: '🇨🇺', name: 'Cuba' },
    { code: '357', flag: '🇨🇾', name: 'Cyprus' },
    { code: '420', flag: '🇨🇿', name: 'Czech Republic' },
    { code: '45', flag: '🇩🇰', name: 'Denmark' },
    { code: '253', flag: '🇩🇯', name: 'Djibouti' },
    { code: '1809', flag: '🇩🇴', name: 'Dominican Republic' },
    { code: '593', flag: '🇪🇨', name: 'Ecuador' },
    { code: '503', flag: '🇸🇻', name: 'El Salvador' },
    { code: '240', flag: '🇬🇶', name: 'Equatorial Guinea' },
    { code: '291', flag: '🇪🇷', name: 'Eritrea' },
    { code: '372', flag: '🇪🇪', name: 'Estonia' },
    { code: '251', flag: '🇪🇹', name: 'Ethiopia' },
    { code: '679', flag: '🇫🇯', name: 'Fiji' },
    { code: '358', flag: '🇫🇮', name: 'Finland' },
    { code: '241', flag: '🇬🇦', name: 'Gabon' },
    { code: '220', flag: '🇬🇲', name: 'Gambia' },
    { code: '995', flag: '🇬🇪', name: 'Georgia' },
    { code: '233', flag: '🇬🇭', name: 'Ghana' },
    { code: '502', flag: '🇬🇹', name: 'Guatemala' },
    { code: '224', flag: '🇬🇳', name: 'Guinea' },
    { code: '245', flag: '🇬🇼', name: 'Guinea-Bissau' },
    { code: '509', flag: '🇭🇹', name: 'Haiti' },
    { code: '504', flag: '🇭🇳', name: 'Honduras' },
    { code: '354', flag: '🇮🇸', name: 'Iceland' },
    { code: '964', flag: '🇮🇶', name: 'Iraq' },
    { code: '1876', flag: '🇯🇲', name: 'Jamaica' },
    { code: '962', flag: '🇯🇴', name: 'Jordan' },
    { code: '7', flag: '🇰🇿', name: 'Kazakhstan' },
    { code: '254', flag: '🇰🇪', name: 'Kenya' },
    { code: '965', flag: '🇰🇼', name: 'Kuwait' },
    { code: '996', flag: '🇰🇬', name: 'Kyrgyzstan' },
    { code: '856', flag: '🇱🇦', name: 'Laos' },
    { code: '371', flag: '🇱🇻', name: 'Latvia' },
    { code: '961', flag: '🇱🇧', name: 'Lebanon' },
    { code: '266', flag: '🇱🇸', name: 'Lesotho' },
    { code: '231', flag: '🇱🇷', name: 'Liberia' },
    { code: '218', flag: '🇱🇾', name: 'Libya' },
    { code: '370', flag: '🇱🇹', name: 'Lithuania' },
    { code: '352', flag: '🇱🇺', name: 'Luxembourg' },
    { code: '261', flag: '🇲🇬', name: 'Madagascar' },
    { code: '265', flag: '🇲🇼', name: 'Malawi' },
    { code: '223', flag: '🇲🇱', name: 'Mali' },
    { code: '356', flag: '🇲🇹', name: 'Malta' },
    { code: '222', flag: '🇲🇷', name: 'Mauritania' },
    { code: '230', flag: '🇲🇺', name: 'Mauritius' },
    { code: '976', flag: '🇲🇳', name: 'Mongolia' },
    { code: '382', flag: '🇲🇪', name: 'Montenegro' },
    { code: '212', flag: '🇲🇦', name: 'Morocco' },
    { code: '258', flag: '🇲🇿', name: 'Mozambique' },
    { code: '95', flag: '🇲🇲', name: 'Myanmar' },
    { code: '264', flag: '🇳🇦', name: 'Namibia' },
    { code: '977', flag: '🇳🇵', name: 'Nepal' },
    { code: '505', flag: '🇳🇮', name: 'Nicaragua' },
    { code: '227', flag: '🇳🇪', name: 'Niger' },
    { code: '234', flag: '🇳🇬', name: 'Nigeria' },
    { code: '389', flag: '🇲🇰', name: 'North Macedonia' },
    { code: '968', flag: '🇴🇲', name: 'Oman' },
    { code: '507', flag: '🇵🇦', name: 'Panama' },
    { code: '595', flag: '🇵🇾', name: 'Paraguay' },
    { code: '51', flag: '🇵🇪', name: 'Peru' },
    { code: '40', flag: '🇷🇴', name: 'Romania' },
    { code: '250', flag: '🇷🇼', name: 'Rwanda' },
    { code: '221', flag: '🇸🇳', name: 'Senegal' },
    { code: '381', flag: '🇷🇸', name: 'Serbia' },
    { code: '232', flag: '🇸🇱', name: 'Sierra Leone' },
    { code: '421', flag: '🇸🇰', name: 'Slovakia' },
    { code: '386', flag: '🇸🇮', name: 'Slovenia' },
    { code: '252', flag: '🇸🇴', name: 'Somalia' },
    { code: '211', flag: '🇸🇸', name: 'South Sudan' },
    { code: '94', flag: '🇱🇰', name: 'Sri Lanka' },
    { code: '249', flag: '🇸🇩', name: 'Sudan' },
    { code: '597', flag: '🇸🇷', name: 'Suriname' },
    { code: '268', flag: '🇸🇿', name: 'Swaziland' },
    { code: '963', flag: '🇸🇾', name: 'Syria' },
    { code: '992', flag: '🇹🇯', name: 'Tajikistan' },
    { code: '255', flag: '🇹🇿', name: 'Tanzania' },
    { code: '1868', flag: '🇹🇹', name: 'Trinidad and Tobago' },
    { code: '216', flag: '🇹🇳', name: 'Tunisia' },
    { code: '256', flag: '🇺🇬', name: 'Uganda' },
    { code: '598', flag: '🇺🇾', name: 'Uruguay' },
    { code: '998', flag: '🇺🇿', name: 'Uzbekistan' },
    { code: '58', flag: '🇻🇪', name: 'Venezuela' },
    { code: '967', flag: '🇾🇪', name: 'Yemen' },
    { code: '260', flag: '🇿🇲', name: 'Zambia' },
    { code: '263', flag: '🇿🇼', name: 'Zimbabwe' }
  ].sort((a, b) => a.name.localeCompare(b.name));

export const DEFAULT_COUNTRY_CODE = '1';  // US by default