import React, { useState, useEffect, useRef, useCallback } from 'react';
import { getFirestore, collection as firestoreCollection, query, where, orderBy, doc, getDoc, getDocs, limit, startAfter, writeBatch, deleteDoc } from 'firebase/firestore';
import { User, Building2, ChevronRight, Bell, Clock, Trash2, MoreVertical } from 'lucide-react';
import { COUNTRY_MAPPING } from '../constants/countries';
import { db } from '../config/firebase';
import { toast } from 'react-hot-toast';
import { saveMonitoredEntity, checkApiLimits } from '../services/api';
import { formatName, getResultTitle } from '../utils/formatters';
import { 
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "./ui/alert-dialog";
import SearchFilter from "./SearchFilter";
import { debounce } from 'lodash';

const HistoryItemMenu = ({ item, onDelete }) => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className="relative" onClick={e => e.stopPropagation()} ref={menuRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="p-1 rounded-full hover:bg-gray-100"
      >
        <MoreVertical className="w-5 h-5 text-gray-500" />
      </button>
      
      {isOpen && (
        <div className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10">
          <div className="py-1">
            <button
              onClick={() => {
                onDelete(item.id);
                setIsOpen(false);
              }}
              className="block w-full text-left px-4 py-2 text-sm text-red-600 hover:bg-gray-100"
            >
              Delete
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

const SearchHistoryDashboard = ({ user, onHistoryItemClick }) => {
    const [searchHistory, setSearchHistory] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filter, setFilter] = useState('all');
    const [searchQuery, setSearchQuery] = useState('');

    const [lastDoc, setLastDoc] = useState(null);
    const [hasMore, setHasMore] = useState(true);
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const ITEMS_PER_PAGE = 10;
  
    // Add new state variables for search optimization
    const [isSearchMode, setIsSearchMode] = useState(false);
    const [allItems, setAllItems] = useState([]);
    const [displayedItems, setDisplayedItems] = useState([]);
  
    // Add new state for search loading
    const [isSearchLoading, setIsSearchLoading] = useState(false);
  
    // Update fetchHistory to include filter
    const fetchHistory = async () => {
      try {
        setLoading(true);
        let historyQuery;
        
        if (filter === 'all') {
          historyQuery = query(
            firestoreCollection(db, 'users', user.uid, 'searches'),
            orderBy('timestamp', 'desc'),
            limit(ITEMS_PER_PAGE)
          );
        } else {
          historyQuery = query(
            firestoreCollection(db, 'users', user.uid, 'searches'),
            where('searchType', '==', filter),
            orderBy('timestamp', 'desc'),
            limit(ITEMS_PER_PAGE)
          );
        }
        
        const querySnapshot = await getDocs(historyQuery);
        
        // Fetch all comments and conclusions in parallel
        const commentsAndConclusions = await Promise.all(
          querySnapshot.docs.map(async (historyDoc) => {
            // Create proper document references using doc()
            const commentsRef = doc(db, 'users', user.uid, 'searchComments', historyDoc.id);
            const conclusionRef = doc(db, 'users', user.uid, 'searchConclusions', historyDoc.id);
            
            const [commentsSnap, conclusionSnap] = await Promise.all([
              getDoc(commentsRef),
              getDoc(conclusionRef)
            ]);
            
            return {
              id: historyDoc.id,
              comments: commentsSnap.data()?.comments || {},
              conclusion: conclusionSnap.data()?.conclusion || ''
            };
          })
        );

        // Combine the search history with comments and conclusions
        const history = querySnapshot.docs.map((doc, index) => {
          const data = doc.data();
          const extras = commentsAndConclusions[index];
          return {
            id: doc.id,
            ...data,
            comments: extras.comments,
            conclusion: extras.conclusion,
            timestamp: data.timestamp?.toDate?.() || data.timestamp
          };
        });
        
        setDisplayedItems(history);
        setSearchHistory(history);
        setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
        setHasMore(querySnapshot.docs.length === ITEMS_PER_PAGE);
        
      } catch (error) {
        console.error('Error fetching search history:', error);
        toast.error('Failed to load search history');
      } finally {
        setLoading(false);
      }
    };

    // Update fetchAllForSearch to handle the current search query
    const fetchAllForSearch = async (currentQuery) => {
      try {
        setLoading(true);
        
        const allItemsQuery = query(
          firestoreCollection(db, 'users', user.uid, 'searches'),
          orderBy('timestamp', 'desc')
        );
        
        const querySnapshot = await getDocs(allItemsQuery);
        const items = querySnapshot.docs.map(doc => {
          const data = doc.data();
          return {
            id: doc.id,
            timestamp: data.timestamp?.toDate?.() || data.timestamp,
            searchType: data.searchType,
            individualName: data.individualName || '',
            companyName: data.companyName || '',
            organization: data.organization || '',
            designation: data.designation || '',
            dob: data.dob || '',
            gender: data.gender || '',
            country: data.country || '🌍 Global',
            matchThreshold: data.matchThreshold || 75,
            alerts: data.alerts || 0,
            hasResults: !!data.results?.length
          };
        });

        setAllItems(items);
        // Use the passed query parameter instead of searchQuery state
        const filteredItems = filterItems(items, filter, currentQuery);
        setDisplayedItems(filteredItems.slice(0, ITEMS_PER_PAGE));
        setSearchHistory(filteredItems.slice(0, ITEMS_PER_PAGE));
        setHasMore(filteredItems.length > ITEMS_PER_PAGE);
        
      } catch (error) {
        console.error('Error fetching search data:', error);
        toast.error('Failed to load search data');
      } finally {
        setLoading(false);
      }
    };

    // Update loadMore to include filter
    const loadMore = async () => {
      if (!hasMore || isLoadingMore) return;
      setIsLoadingMore(true);
      
      try {
        if (isSearchMode) {
          const currentLength = displayedItems.length;
          const filteredItems = filterItems(allItems);
          const nextItems = filteredItems.slice(currentLength, currentLength + ITEMS_PER_PAGE);
          
          setDisplayedItems(prev => [...prev, ...nextItems]);
          setSearchHistory(prev => [...prev, ...nextItems]); // Keep for backward compatibility
          setHasMore(currentLength + ITEMS_PER_PAGE < filteredItems.length);
        } else {
          let moreHistoryQuery;
          
          if (filter === 'all') {
            moreHistoryQuery = query(
              firestoreCollection(db, 'users', user.uid, 'searches'),
              orderBy('timestamp', 'desc'),
              startAfter(lastDoc),
              limit(ITEMS_PER_PAGE)
            );
          } else {
            moreHistoryQuery = query(
              firestoreCollection(db, 'users', user.uid, 'searches'),
              where('searchType', '==', filter),
              orderBy('timestamp', 'desc'),
              startAfter(lastDoc),
              limit(ITEMS_PER_PAGE)
            );
          }
          
          const querySnapshot = await getDocs(moreHistoryQuery);
          const moreHistory = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
            timestamp: doc.data().timestamp?.toDate?.() || doc.data().timestamp
          }));
          
          setDisplayedItems(prev => [...prev, ...moreHistory]);
          setSearchHistory(prev => [...prev, ...moreHistory]);
          setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
          setHasMore(querySnapshot.docs.length === ITEMS_PER_PAGE);
        }
      } catch (error) {
        console.error('Error loading more items:', error);
        toast.error('Failed to load more items');
      } finally {
        setIsLoadingMore(false);
      }
    };

    // Update handleSearchChange to handle single-letter searches
    const handleSearchChange = (query) => {
      setSearchQuery(query);
      
      // Always filter if we have data, regardless of query length
      if (isSearchMode && allItems.length > 0) {
        const filteredItems = filterItems(allItems, filter, query);
        setDisplayedItems(filteredItems.slice(0, ITEMS_PER_PAGE));
        setSearchHistory(filteredItems.slice(0, ITEMS_PER_PAGE));
        setHasMore(filteredItems.length > ITEMS_PER_PAGE);
      } else if (query.length > 0) { // Trigger search for any non-empty query
        debouncedSearch(query);
      } else {
        // Reset to normal view if query is empty
        setIsSearchMode(false);
        fetchHistory();
      }
    };

    // Update debouncedSearch to pass the current query
    const debouncedSearch = useCallback(
      debounce(async (query) => {
        if (query) {
          setIsSearchLoading(true);
          try {
            if (!isSearchMode) {
              setIsSearchMode(true);
              await fetchAllForSearch(query);
            }
          } finally {
            setIsSearchLoading(false);
          }
        } else {
          setIsSearchMode(false);
          await fetchHistory();
        }
      }, 300),
      []  // Empty dependency array since we're passing query directly
    );

    // Update handleFilterChange to reset pagination
    const handleFilterChange = async (newFilter) => {
      setFilter(newFilter);
      setLastDoc(null); // Reset pagination
      setHasMore(true);
      
      if (isSearchMode) {
        const filteredItems = filterItems(allItems, newFilter, searchQuery);
        setDisplayedItems(filteredItems.slice(0, ITEMS_PER_PAGE));
        setSearchHistory(filteredItems.slice(0, ITEMS_PER_PAGE));
        setHasMore(filteredItems.length > ITEMS_PER_PAGE);
      } else {
        await fetchHistory();
      }
    };

    // Add filterItems function
    const filterItems = (items, currentFilter = filter, query = searchQuery) => {
      return items.filter(item => {
        const matchesFilter = currentFilter === 'all' || item.searchType === currentFilter;
        
        if (!query) return matchesFilter;
        
        const searchLower = query.toLowerCase();
        const matchesSearch = (item.searchType === 'individual' 
          ? item.individualName?.toLowerCase().includes(searchLower)
          : item.companyName?.toLowerCase().includes(searchLower)) ||
          item.country?.toLowerCase().includes(searchLower) ||
          item.organization?.toLowerCase().includes(searchLower) ||
          item.designation?.toLowerCase().includes(searchLower);
        
        return matchesFilter && matchesSearch;
      });
    };

    // Update initial useEffect
    useEffect(() => {
      if (!isSearchMode && user?.uid) {
        fetchHistory();
      }
    }, [user?.uid, filter]);

    const handleHistoryItemClick = async (historyItem, e) => {
      if (e?.target?.closest('[data-menu]')) {
        return;
      }

      // Since we already have all the data, just format the results
      const resultsWithId = (historyItem.results || []).map(result => ({
        ...result,
        searchHistoryId: historyItem.id,  // Store search history ID separately
        id: result.id || `${historyItem.id}_${Math.random().toString(36).substr(2, 9)}` // Keep original ID or generate unique one
      }));

      onHistoryItemClick({
        ...historyItem,
        results: resultsWithId,
        searchTimestamp: historyItem.timestamp
      });
    };

    const handleDeleteHistoryItem = async (itemId) => {
      try {
        const toastId = toast.loading('Deleting search...');
        const docRef = doc(db, 'users', user.uid, 'searches', itemId);
        await deleteDoc(docRef);
        
        // Update both displayedItems and searchHistory states immediately
        setDisplayedItems(prev => prev.filter(item => item.id !== itemId));
        setSearchHistory(prev => prev.filter(item => item.id !== itemId));
        
        // If we're in search mode, also update allItems
        if (isSearchMode) {
          setAllItems(prev => prev.filter(item => item.id !== itemId));
        }
        
        toast.success('Search deleted', { id: toastId });
      } catch (error) {
        console.error('Error deleting history item:', error);
        toast.error('Failed to delete search');
        // Refresh data in case of error to ensure consistency
        if (isSearchMode) {
          fetchAllForSearch(searchQuery);
        } else {
          fetchHistory();
        }
      }
    };

    const filteredHistory = searchHistory.filter(item => {
      const matchesFilter = filter === 'all' || item.searchType === filter;
      
      if (!searchQuery) return matchesFilter;
      
      const searchLower = searchQuery.toLowerCase();
      const matchesSearch = (item.searchType === 'individual' 
        ? item.individualName?.toLowerCase().includes(searchLower)
        : item.companyName?.toLowerCase().includes(searchLower)) ||
        item.country?.toLowerCase().includes(searchLower) ||
        item.organization?.toLowerCase().includes(searchLower) ||
        item.designation?.toLowerCase().includes(searchLower);
      
      return matchesFilter && matchesSearch;
    });
  
    const handleClearHistory = async () => {
      try {
        const toastId = toast.loading('Clearing search history...');
        const batch = writeBatch(db);
        
        // Get all search history documents
        const searchHistoryRef = firestoreCollection(db, 'users', user.uid, 'searches');
        const searchHistoryDocs = await getDocs(searchHistoryRef);
        
        // Add all documents to batch delete
        searchHistoryDocs.forEach(doc => {
          batch.delete(doc.ref);
        });
    
        // Commit the batch
        await batch.commit();
        
        // Update local state after successful deletion
        setSearchHistory([]);
        toast.success('Search history cleared', { id: toastId });
      } catch (error) {
        console.error('Error clearing history:', error);
        toast.error('Failed to clear search history');
      }
    };

    return (
      <div className="bg-white rounded-2xl shadow-sm border p-6">
        <div className="opacity-0 animate-fade-in">
          {/* Header with responsive spacing */}
          <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between mb-6">
            <div className="flex items-center mb-4 sm:mb-0">
              <h2 className="text-2xl font-semibold">Search History</h2>
              {searchHistory.length > 0 && (
                <AlertDialog>
                  <AlertDialogTrigger asChild>
                    <button 
                      className="relative ml-2 flex items-center justify-center w-8 h-8 rounded-full bg-red-50 hover:bg-red-100 focus:outline-none focus:ring-0"
                    >
                      <Trash2 className="w-4 h-4 text-red-600" />
                    </button>
                  </AlertDialogTrigger>
                  <AlertDialogContent onPointerDownOutside={(e) => e.preventDefault()}>
                    <AlertDialogHeader>
                      <AlertDialogTitle>Clear Search History</AlertDialogTitle>
                      <AlertDialogDescription>
                        This will permanently delete your search history. This action cannot be undone.
                      </AlertDialogDescription>
                    </AlertDialogHeader>
                    <AlertDialogFooter>
                      <AlertDialogCancel 
                        onPointerDown={(e) => e.preventDefault()}
                        onClick={() => {
                          // Reset any state or side effects here if needed
                        }}
                      >
                        Cancel
                      </AlertDialogCancel>
                      <AlertDialogAction
                        onClick={handleClearHistory}
                        className="bg-red-600 hover:bg-red-700 focus:ring-red-600 text-white"
                      >
                        Clear History
                      </AlertDialogAction>
                    </AlertDialogFooter>
                  </AlertDialogContent>
                </AlertDialog>
              )}
            </div>
            
            {/* Filter section - updated with search bar */}
            <div className="flex flex-col sm:flex-row gap-2 w-full sm:w-auto">
              <SearchFilter 
                value={searchQuery}
                onChange={handleSearchChange}
                placeholder="Search"
                isLoading={isSearchLoading}
                className="border-gray-200"
              />
              <div className="flex gap-2 overflow-x-auto sm:overflow-visible">
                <button
                  onClick={() => handleFilterChange('all')}
                  className={`flex-none px-4 py-2 rounded-lg text-sm ${
                    filter === 'all' 
                      ? 'bg-gray-100 text-gray-900'
                      : 'text-gray-600 hover:bg-gray-50'
                  }`}
                >
                  All
                </button>
                <button
                  onClick={() => handleFilterChange('individual')}
                  className={`flex-none px-4 py-2 rounded-lg text-sm ${
                    filter === 'individual'
                      ? 'bg-gray-100 text-gray-900'
                      : 'text-gray-600 hover:bg-gray-50'
                  }`}
                >
                  Individuals
                </button>
                <button
                  onClick={() => handleFilterChange('company')}
                  className={`flex-none px-4 py-2 rounded-lg text-sm ${
                    filter === 'company'
                      ? 'bg-gray-100 text-gray-900'
                      : 'text-gray-600 hover:bg-gray-50'
                  }`}
                >
                  Companies
                </button>
              </div>
            </div>
          </div>
  
          {/* History Items */}
          <div className="-mx-6 sm:mx-0">
            <div className="space-y-3">
              {/* Only show loading state on initial page load, not during search */}
              {loading && !isSearchMode ? (
                <div className="space-y-4">
                  {[1, 2, 3].map((i) => (
                    <div key={i} className="animate-pulse">
                      <div className="flex items-center p-4 sm:p-4 border-b sm:border sm:rounded-xl">
                        {/* Icon skeleton */}
                        <div className="flex-shrink-0">
                          <div className="w-10 h-10 bg-gray-200 rounded-full"></div>
                        </div>
                        {/* Content skeleton */}
                        <div className="ml-4 flex-1">
                          <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                          <div className="mt-2 space-y-2">
                            <div className="h-3 bg-gray-200 rounded w-1/4"></div>
                            <div className="h-3 bg-gray-200 rounded w-1/2"></div>
                          </div>
                        </div>
                        {/* Actions skeleton */}
                        <div className="flex items-center gap-2">
                          <div className="w-8 h-8 bg-gray-200 rounded-full"></div>
                          <div className="w-5 h-5 bg-gray-200 rounded"></div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : displayedItems.length === 0 ? (
                <div className="text-center py-8">
                  <Clock className="mx-auto h-12 w-12 text-gray-400" />
                  <h3 className="mt-2 text-sm font-semibold text-gray-900">No search history</h3>
                  <p className="mt-1 text-sm text-gray-500">
                    Your past searches will appear here
                  </p>
                </div>
              ) : (
                displayedItems.map((search) => {
                  // console.log('Search History Item:', {
                  //   searchType: search.searchType,
                  //   name: search.individualName || search.companyName,
                  //   hasResults: search.hasResults
                  // });
                  
                  return (
                    <div
                      key={search.id}
                      onClick={(e) => handleHistoryItemClick(search, e)}
                      className="w-full flex items-center p-4 sm:p-4 hover:bg-gray-100 transition-colors text-left border-b sm:border sm:rounded-xl cursor-pointer"
                    >
                      {/* Icon */}
                      <div className="flex-shrink-0">
                        {search.searchType === 'individual' ? (
                          <User className="w-10 h-10 text-gray-400" />
                        ) : (
                          <Building2 className="w-10 h-10 text-gray-400" />
                        )}
                      </div>
    
                      {/* Content */}
                      <div className="ml-4 flex-1 min-w-0">
                        <h3 className="text-base sm:text-lg font-medium text-gray-900 truncate">
                          {search.searchType === 'individual' ? formatName(search.individualName) : formatName(search.companyName)}
                        </h3>
                        {/* Mobile layout */}
                        <div className="flex flex-col gap-1 sm:hidden mt-1 text-sm text-gray-500">
                          <time className="truncate">
                            {search.timestamp instanceof Date && search.timestamp.toLocaleDateString('en-US', {
                              month: 'long',
                              day: 'numeric',
                              year: 'numeric'
                            })}
                          </time>
                          <span className="px-2 py-1 bg-gray-100 rounded-lg w-fit">
                            {search.country}
                          </span>
                          {search.alerts > 0 && (
                            <span className="flex items-center text-red-600 w-fit">
                              ⚠️ {search.alerts} {search.alerts === 1 ? 'alert' : 'alerts'}
                            </span>
                          )}
                        </div>
                        {/* Desktop layout */}
                        <div className="hidden sm:flex flex-wrap items-center gap-2 mt-1 text-sm text-gray-500">
                          <time className="truncate">
                            {search.timestamp instanceof Date && search.timestamp.toLocaleDateString('en-US', {
                              month: 'long',
                              day: 'numeric',
                              year: 'numeric'
                            })}
                            <span className="inline">
                              {' at ' + search.timestamp.toLocaleTimeString('en-US', {
                                hour: 'numeric',
                                minute: 'numeric',
                                hour12: true
                              })}
                            </span>
                          </time>
                          <span className="px-2 py-1 bg-gray-100 rounded-lg truncate">
                            {search.country}
                          </span>
                          {search.alerts > 0 && (
                            <span className="flex items-center text-red-600">
                              ⚠️ {search.alerts} {search.alerts === 1 ? 'alert' : 'alerts'}
                            </span>
                          )}
                        </div>
                      </div>
    
                      {/* Replace the existing Chevron section with this: */}
                      <div className="flex items-center">
                        <div data-menu>
                          <HistoryItemMenu 
                            item={search}
                            onDelete={handleDeleteHistoryItem}
                          />
                        </div>
                        <div className="cursor-pointer">
                          <ChevronRight className="h-5 w-5 text-gray-400 flex-shrink-0 ml-4" />
                        </div>
                      </div>
                    </div>
                  );
                })
              )}
            </div>
          </div>

          {/* Add Load More button here */}
          {!loading && displayedItems.length > 0 && hasMore && (
            <div className="flex justify-center py-4">
              <button
                onClick={loadMore}
                disabled={isLoadingMore}
                className="px-4 py-2 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200 disabled:opacity-50"
              >
                {isLoadingMore ? 'Loading...' : 'Load More'}
              </button>
            </div>
          )}
        </div>
      </div>
    );
  };

  export default SearchHistoryDashboard;