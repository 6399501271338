import React, { useState, useRef, useEffect } from 'react';
import { ChevronDown } from 'lucide-react';
import { phoneCodes } from '../constants/countries';

const PhoneCodeSelect = ({ value, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [activeIndex, setActiveIndex] = useState(-1);
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  // Split value into code and country if it's a combined value
  const [currentCode, currentCountry] = value?.split('|') || ['1', 'United States'];

  // Find the selected country code object
  const selectedCountry = phoneCodes.find(c => 
    c.code === currentCode && (
      currentCountry ? c.name === currentCountry : // If country specified, match exactly
      c.code !== '1' || c.name === 'United States' // Otherwise use default logic
    )
  ) || phoneCodes.find(c => c.code === '1' && c.name === 'United States');

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleKeyDown = (e) => {
    if (!isOpen) return;
    
    const filteredCodes = phoneCodes.filter(code => 
      code.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      code.code.includes(searchTerm)
    );

    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        setActiveIndex(prev => 
          prev < filteredCodes.length - 1 ? prev + 1 : 0
        );
        break;
      case 'ArrowUp':
        e.preventDefault();
        setActiveIndex(prev => 
          prev > 0 ? prev - 1 : filteredCodes.length - 1
        );
        break;
      case 'Enter':
        e.preventDefault();
        if (activeIndex >= 0) {
          onChange(`${filteredCodes[activeIndex].code}|${filteredCodes[activeIndex].name}`);
          setIsOpen(false);
        }
        break;
      case 'Escape':
        setIsOpen(false);
        break;
    }
  };

  const filteredCodes = phoneCodes.filter(code => 
    code.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    code.code.includes(searchTerm)
  );

  return (
    <div className="relative" ref={dropdownRef} onKeyDown={handleKeyDown}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="w-full h-[38px] flex items-center justify-between px-3 border border-gray-300 rounded-md shadow-sm bg-white text-sm focus:outline-none focus:ring-2 focus:ring-primary-500 focus:border-primary-500"
      >
        <span className="flex items-center space-x-2">
          <span className="text-xl">{selectedCountry.flag}</span>
          <span className="text-gray-900">+{selectedCountry.code}</span>
        </span>
        <ChevronDown className={`w-4 h-4 text-gray-400 transition-transform ${isOpen ? 'transform rotate-180' : ''}`} />
      </button>

      {isOpen && (
        <div className="absolute z-50 mt-1 w-[280px] max-h-[360px] bg-white rounded-md shadow-lg border border-gray-200 overflow-hidden">
          <div className="sticky top-0 bg-white border-b border-gray-200">
            <input
              ref={inputRef}
              type="text"
              className="w-full h-10 px-3 border-0 focus:outline-none focus:ring-0"
              placeholder="Search countries..."
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
                setActiveIndex(-1);
              }}
              autoFocus
            />
          </div>
          <div className="overflow-auto max-h-[312px]">
            {filteredCodes.map((country, index) => (
              <button
                key={`${country.code}-${country.name}`}
                className={`w-full text-left px-3 py-2.5 flex items-center space-x-3 hover:bg-gray-50 ${
                  index === activeIndex ? 'bg-gray-50' : ''
                }`}
                onClick={() => {
                  onChange(`${country.code}|${country.name}`);
                  setIsOpen(false);
                }}
              >
                <span className="text-xl">{country.flag}</span>
                <span className="flex-1 text-sm text-gray-900">{country.name}</span>
                <span className="text-sm text-gray-500">+{country.code}</span>
              </button>
            ))}
            {filteredCodes.length === 0 && (
              <div className="px-3 py-2 text-sm text-gray-500">No results found</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default PhoneCodeSelect;